import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  Textarea, useToast,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { Formik } from "formik";
import * as React from "react";
import config from "../../config";
import { handleReview } from "../../helpers/guestportalfetch";
import { omitNullAndUndefined } from "../../helpers/helpers";
import { sendHubspotEvent } from "../../helpers/hubspot";

const baseUrl = config.baseUrl;

type Props = {
  modalInitiateOpen: boolean;
  reservationData: any;
  onReviewClose: Function;
};

const ReviewModal: React.FC<Props> = ({
  modalInitiateOpen,
  reservationData,
  onReviewClose
}) => {
  const toast = useToast()
  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;
  const [modalOpen, setModalState] = React.useState(modalInitiateOpen);
  const [starRating, setStarRating] = React.useState(null);
  const [hoverRating, setHoverRating] = React.useState(null);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  // Opens a new window for the Review Redirect URL only if User Leaves 4 or 5 Stars
  React.useEffect(() => {
    let timer = null;

    if (formSubmitted) {
      // HubSpot Custom Event //
      const eventName = "pe21053466_review_submitted";
      const properties = {
        house_number: reservationData.houseNumber,
        reservation_number: reservationData.reservationNumber,
        hs_page_url: window.location.href,
        rating: starRating,
      }
      const eventData = {
        eventName,
        properties,
        ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
      };

      if (userEmail) {
        // Fire and forget
        (async () => {
          try {
            await sendHubspotEvent(eventData);
          } catch (error) {
            console.error("Error sending HubSpot event:", error);
          }
        })();
      }
      // END HubSpot Custom Event // 
    }

    if (formSubmitted && starRating > 3) {
      timer = setTimeout(() => {
        window.open(channelURL, '_blank');
      }, 5000);
    }

    // cleanup function to clear the timer if the component unmounts before 5 seconds.
    return () => timer && clearTimeout(timer);
  }, [formSubmitted, starRating]);


  // Model State Handling
  React.useEffect(() => {
    setModalState(modalInitiateOpen);
  }, [modalInitiateOpen]);

  function openModal() {
    setModalState(true);
  }
  function closeModal() {
    setModalState(false);
  }


  // HubSpot Custom Event // 

  const eventFiredRef = React.useRef(false); // Track if event has been fired
  const eventName = "pe21053466_review_page_loaded";
  const properties = omitNullAndUndefined({
    house_number: reservationData.houseNumber,
    reservation_number: reservationData.reservationNumber,
    review_page_type: 'Review Modal (Guest Portal)',
    hs_page_url: window.location.href,
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };

  React.useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          //console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change

  // END HubSpot Custom Event // 


  // Review Submits Immediately if 4 or 5 Stars, No Option to Leave Comments
  function clicked(e: any) {
    setStarRating(e.target.dataset.value);
    //console.log("Clicked:",e.target.dataset.value)

    if (e.target.dataset.value >= 4) {
      setFormSubmitted(true);

      //console.log("reservationData for review", reservationData)

      const fields = {
        starRating: getStarRatingString(e.target.dataset.value),
        reservationNumber: reservationData.reservationNumber,
        reservationName: reservationData.reservationNumber,
        houseNumber: reservationData.houseNumber,
        comments: ""
      };

      handleReview(fields).then((response) => {
        //console.log("Response:",response);
        //closeModal();
        //onReviewClose();
      });
    }
  }

  function modalCloseAndSubmit() {
    onReviewClose();
    closeModal();
  }

  // Hover Effects for Rating Stars
  function hover(e: any) {
    setHoverRating(e.target.dataset.value);
  }

  function leaveHover(e: any) {
    setHoverRating(starRating);
  }


  // Converts Star Rating Number to String for HubSpot
  function getStarRatingString(rating) {
    if (rating == 1) {
      return "1 Star";
    } else if (rating == 2) {
      return "2 Stars";
    } else if (rating == 3) {
      return "3 Stars";
    } else if (rating == 4) {
      return "4 Stars";
    } else if (rating == 5) {
      return "5 Stars";
    }
  }


  // Google is Default Review Redirect if Not Airbnb or VRBO Types
  //const GOOGLE_URL = 'https://g.page/r/CVJkG59IPaFLEB0/review';
  const YELP_URL = 'https://www.yelp.com/writeareview/biz/jPgYaQo4entpA3tXIXAFsQ?return_url=%2Fbiz%2FjPgYaQo4entpA3tXIXAFsQ&review_origin=biz-details-war-button'
  const AIRBNB_URL = 'https://www.airbnb.com/trips/v1';
  const VRBO_URL = 'https://www.vrbo.com/traveler/th/bookings';

  let channelName = 'Google';
  let channelURL = YELP_URL; // Switching from Google to Yelp Review for Direct Bookings as of June 2024

  if (reservationData?.typename) {
    const typeName = reservationData.typename.toLowerCase();

    if (typeName === 'SC-ABnB' || typeName.includes('airbnb')) {
      channelName = 'Airbnb';
      channelURL = AIRBNB_URL;
    } else if (typeName === 'HAFamOLB' || typeName.includes('vrbo') || typeName.includes('homeaway')) {
      channelName = 'VRBO';
      channelURL = VRBO_URL;
    }
  }

  return (
    <Box height={0}>
      <Modal isOpen={modalOpen} onClose={() => void 0} size={{ base: 'full', md: '6xl' }}>
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent borderRadius="none">
          <ModalCloseButton size="lg" onClick={modalCloseAndSubmit} />
          {!formSubmitted ? (
            <ModalBody padding="0">
              <Flex align="center">
                <AspectRatio
                  ratio={3 / 4.4}
                  width="50%"
                  maxW={{ lg: "md" }}
                  display={{ base: "none", lg: "flex" }}
                >
                  <Image
                    src="../media/sunset.jpg"
                    alt="Sunset"
                    fallback={<Skeleton />}
                    objectFit={"cover"}
                    align={"left"}
                  />
                </AspectRatio>

                <Flex
                  direction="column"
                  align="center"
                  flex="1"
                  py="12"
                  px={{ base: "4", md: "6" }}
                >
                  <Box maxW="lg" mx="auto">
                    <Box
                      textAlign="center"
                      maxW={{ base: "full", sm: "lg" }}
                      mx="auto"
                      mt="10"
                    >
                      <Heading fontWeight="extrabold">
                        How was your stay?
                      </Heading>
                      <Text fontSize="lg" mt="6" mb="3">
                        We strive to earn 5-stars, but if your stay was anything
                        less, we want to know how to improve, let us know below!
                      </Text>
                      <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        pt={4}
                      >
                        <Formik
                          initialValues={{
                            comments: "",
                          }}
                          onSubmit={async (values) => {
                            setFormSubmitted(true);

                            const fields = {
                              starRating: getStarRatingString(starRating),
                              reservationNumber: reservationData["reservationNumber"],
                              reservationName: reservationData["reservationNumber"],
                              houseNumber: reservationData["houseNumber"],
                              comments: values.comments
                            };

                            handleReview(fields).then((response) => {
                              //console.log("Response:",response);
                              onReviewClose();
                            });
                          }}
                        >
                          {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => (
                            <form
                              onSubmit={handleSubmit}
                              className="guest-form"
                            >
                              <Flex
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                              >
                                <div
                                  className="star-container"
                                  onMouseLeave={leaveHover}
                                >
                                  {starRating < 1 && hoverRating < 1 ? (
                                    <span
                                      className="star-rating"
                                      data-value="1"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9734;
                                    </span>
                                  ) : (
                                    <span
                                      className="star-rating"
                                      data-value="1"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9733;
                                    </span>
                                  )}
                                  {starRating < 2 && hoverRating < 2 ? (
                                    <span
                                      className="star-rating"
                                      data-value="2"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9734;
                                    </span>
                                  ) : (
                                    <span
                                      className="star-rating"
                                      data-value="2"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9733;
                                    </span>
                                  )}
                                  {starRating < 3 && hoverRating < 3 ? (
                                    <span
                                      className="star-rating"
                                      data-value="3"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9734;
                                    </span>
                                  ) : (
                                    <span
                                      className="star-rating"
                                      data-value="3"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9733;
                                    </span>
                                  )}
                                  {starRating < 4 && hoverRating < 4 ? (
                                    <span
                                      className="star-rating"
                                      data-value="4"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9734;
                                    </span>
                                  ) : (
                                    <span
                                      className="star-rating"
                                      data-value="4"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9733;
                                    </span>
                                  )}
                                  {starRating < 5 && hoverRating < 5 ? (
                                    <span
                                      className="star-rating"
                                      data-value="5"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9734;
                                    </span>
                                  ) : (
                                    <span
                                      className="star-rating"
                                      data-value="5"
                                      onMouseEnter={hover}
                                      onClick={clicked}
                                    >
                                      &#9733;
                                    </span>
                                  )}
                                </div>
                                {starRating < 4 && starRating != null && (
                                  <>
                                    <Textarea
                                      id="comments"
                                      name="comments"
                                      variant="outline"
                                      bg={"whiteAlpha.700"}
                                      size="lg"
                                      height={{ base: "100px", md: "150px" }}
                                      maxLength={300}
                                      placeholder={
                                        "Please let us know how we could have improved your stay."
                                      }
                                      onChange={handleChange}
                                      value={values.comments}
                                      mt={8}
                                      mb={4}
                                      className={
                                        starRating < 4 && starRating != null
                                          ? ""
                                          : "container-hidden"
                                      }
                                    />
                                    <Flex
                                      justifyContent={"end"}
                                      pt={{ base: 2, md: 8 }}
                                      pb={8}
                                      w={"100%"}
                                      className={
                                        starRating < 4 && starRating != null
                                          ? ""
                                          : "container-hidden"
                                      }
                                    >
                                      <Button
                                        type="submit"
                                        width="90px"
                                        className="dm-button-orange-outline"
                                        mr={2}
                                        onClick={modalCloseAndSubmit}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        width="90px"
                                        className="dm-button-orange-solid"
                                        color={"white"}
                                        onClick={() => {
                                          setTimeout(() => {
                                            toast({
                                              title: 'Review Submitted',
                                              description: "Thank you for sharing your feedback.",
                                              status: 'success',
                                              duration: 9000,
                                              isClosable: true,
                                              position: 'top',
                                            });
                                          }, 250);
                                          closeModal();
                                        }}
                                      >
                                        Send
                                      </Button>
                                    </Flex>
                                  </>
                                )}
                              </Flex>
                            </form>
                          )}
                        </Formik>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              </Flex>
            </ModalBody>
          ) : (
            <ModalBody padding="0">
              <Flex align="center">
                <AspectRatio
                  ratio={3 / 4.2}
                  width="50%"
                  maxW={{ lg: "md" }}
                  display={{ base: "none", md: "flex" }}
                >
                  <Image
                    src="../media/sunset.jpg"
                    alt="Sunset"
                    fallback={<Skeleton />}
                    objectFit={"cover"}
                    align={"left"}
                  />
                </AspectRatio>

                <Flex
                  direction="column"
                  align="center"
                  flex="1"
                  py="12"
                  px={{ base: "4", md: "6" }}
                >
                  {starRating > 3 ?
                    <Box maxW="lg" mx="auto">
                      <Box
                        textAlign="center"
                        maxW={{ base: "full", sm: "lg" }}
                        mx="auto"
                        mt="10"
                      >
                        <Heading fontWeight="extrabold">
                          Sending you to review Online at {channelName}!
                        </Heading>

                        <Flex
                          pt="10"
                          width={"100%"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <Text>
                            We really appreciate you taking the time to share your rating with us.
                            Could we ask you to take a few more minutes and share your experience on {channelName}?
                            <br /><br />
                            We'll open a new tab in a few seconds. Don't see it?{' '}
                            <Link href={channelURL} target={"_blank"} textDecoration={'underline'}>Click here</Link>.
                          </Text>
                        </Flex>
                        <Flex
                          pt="10"
                          width={"100%"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          Ready to book your next adventure?
                        </Flex>
                        <Flex
                          pt="10"
                          width={"100%"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <Link
                            href={baseUrl}
                            w={{ base: "100%", lg: "auto" }}
                            className="no-underline"
                            target={"_blank"}
                          >
                            <Button
                              className="dm-button-orange-pill"
                              w={{ base: "100%", lg: "300px" }}
                              boxShadow="base"
                            >
                              Book another home
                            </Button>
                          </Link>
                        </Flex>
                        <Flex
                          w={"100%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          pt={4}
                        ></Flex>
                      </Box>
                    </Box>
                    :
                    <Box>
                      <Box
                        textAlign="center"
                        maxW={{ base: "full", sm: "lg" }}
                        mx="auto"
                        mt="10"
                      >
                        <Heading fontWeight="extrabold">
                          Thank you.
                        </Heading>

                        <Flex
                          pt="10"
                          width={"100%"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <Text>
                            We really appreciate you taking the time to share your feedback.
                            <br /><br />
                          </Text>
                        </Flex>
                        <Flex
                          pt="10"
                          width={"100%"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <Button
                            className="dm-button-orange-pill"
                            w={{ base: "100%", lg: "300px" }}
                            boxShadow="base"
                            onClick={closeModal}
                          >
                            Close
                          </Button>
                        </Flex>
                        <Flex
                          w={"100%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexDirection={"column"}
                          pt={4}
                        ></Flex>
                      </Box>
                    </Box>
                  }
                </Flex>
              </Flex>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export { ReviewModal };

