import {
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { addDays, format, parseISO } from "date-fns";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { fetchGlobalCalendar } from "../../../helpers/availabilityService";
import { MAX_END_DATE } from "../../../helpers/constants";
import {
  fetchSearchResults,
  getFavoritesWithHouseData,
} from "../../../helpers/fetch";
import { formatDateToYYYYMMDD } from "../../../helpers/helpers";
import { getCheckOutById } from "../../../helpers/reservationService";
import { MobileSearch } from "./Mobile";
import { MAX_GUESTS, MIN_GUESTS, TOWNS } from "./constants";

function SearchWidget() {
  const navigate = useNavigate();
  const location = useLocation();

  /* This is where the context gets set to pass into the SearchResultsGrid */
  const {
    searchResults,
    setSearchResults,
    setFlexSearchResults,
    queryOffset,
    setTotalResults,
    setLoading,
    setBufferLoading,
    setQueryOffset,
    guests,
    setGuests,
    dogs,
    setDogs,
    contextDateRange,
    setContextDateRange,
    setPriceSearch,
    setMaxSearchPriceValue,
    setMinSearchPriceValue,
    setPathingFilterSettings,
    userFavorites,
    setUserFavorites,
    setFavoritesLoading,
    setNumFavorites,
    setUserEmail,
    setEmailLoading,
    setGlobalCheckIns,
    globalCalendarLoading,
    setGlobalCalendarLoading,
    setSearchWithDates,
    boundingBox,
    setBoundingBox,
    triggerSearch,
    mapAreaSearch,
    setMapAreaSearch,
    boundingBoxMoved,
    setMapDisplayOnLoad,
    setRandomResults,
  } = React.useContext(SearchResultsContext);

  const { user } = useUser(); // Clerk
  const [town, setTown] = React.useState(null);
  const [startDate, endDate] = contextDateRange;
  const [searchMode, setSearchMode] = React.useState(false);
  const [fetchKey, setFetchKey] = React.useState(0);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [conditionalCalendarProps, setConditionalCalendarProps] =
    React.useState(null);
  const [datesToGrayOut, setDatesToGrayOut] = React.useState([]);
  const [rebookReservation, setRebookReservation] = React.useState(localStorage.getItem("dm-rebook-reservation") || null);

  // Mobile-Related Items for Modal Search Menu
  const isMobile = useBreakpointValue({ base: true, md: false }); // for Mobile Search Bar
  const [isModalOpen, setModalOpen] = React.useState(false); // State to control modal
  const openModal = () => setModalOpen(true); // Function to open the modal
  const closeModal = () => setModalOpen(false); // Function to close the modal

  // Mobile Click Handling function
  const closeModalAndSearch = () => {
    closeModal();
    handleSearchClick();
  };

  /* When the user clicks the search button, we are now performing an actual Search (as opposed to Explore that just shows 20 sample listings) 
  That means we onSearch (???), set searchMode to true (to get filtered results), and increment fetchKey just to re-render/re-trigger data fetching */
  const handleSearchClick = () => {
    if ((startDate && endDate) || guests || town || dogs) {
      //console.log("Sufficient Search Data. Searching with:", startDate, endDate, guests, town)
      setSearchMode(true); // Listings Search
      setPriceSearch(true);
    } else {
      //console.log("Insufficient Search Data. Explore search.", startDate, endDate, guests, town)
      setSearchMode(false); // Force Explore Search
      setPriceSearch(false);
    }

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setContextDateRange([null, null]); // Clear out partial date ranges
    }

    if (startDate && endDate) {
      setSearchWithDates(true);
    } else {
      setSearchWithDates(false);
    }

    setFetchKey((prevKey) => prevKey + 1);
    navigate(`/?town=${town ? town : ''}&startdate=${startDate ? formatDateToYYYYMMDD(startDate) : ''}&enddate=${endDate ? formatDateToYYYYMMDD(endDate) : ''}&guests=${guests ? guests : ''}&tags=${dogs ? 'Dog%20Friendly' : ''}`);
  };

  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false);
  const [rebookParameterReservation, setRebookParameterReservation] = React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);
  const [rebookCouponReady, setRebookCouponReady] = React.useState(false);
  const [rebookDiscountPercentage, setRebookDiscountPercentage] = React.useState(0);

  React.useEffect(() => {
    // Moving this here from ReservationContext to make sure it fires in time
    const setAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");
      if (attribution) {
        localStorage.setItem("dm-attribution", attribution);
      }
      if (rebookMode) {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
        localStorage.setItem("dm-rebook-reservation", rebookMode);
      }
    };

    setAttributionFromUrl();

    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    const rebookValue = localStorage.getItem("dm-rebook-reservation"); // local version of variable for rebookParameterReservation
    setRebookParameterReservation(
      localStorage.getItem("dm-rebook-reservation")
    );
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    //console.log('rebookParameter', rebookParameter, 'rebookParameterReservation', localStorage.getItem('dm-rebook-reservation'), 'rebookValue', rebookValue)

    let isRebook; // local version of variable for rebookMode

    // If rebookParameter is set, check if it's been less than 24 hours since the last rebook
    if (rebookParameter) {
      //console.log('rebookParameter Time check')
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneWeekInMilliseconds) {
        //console.log('rebookMode set')
        isRebook = true;
        setRebookMode(true);
      } else {
        //console.log('rebookMode not set')
        isRebook = false;
        setRebookMode(false);
      }
    }

    //console.log('isRebook', isRebook, 'rebookMode', rebookMode, 'rebookParameterReservation', rebookParameterReservation, 'rebookValue', rebookValue)

    // Function to get the checkout date and coupon code for rebook mode
    const getRebookCheckoutDateAndCoupon = async () => {
      //console.log('rebookParameterReservation', rebookValue)
      if (rebookValue === "stayfi") {
        //console.log("stayfi branch")
        setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        setRebookDiscountPercentage(0.12);
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "4be10cd6da4b41ee20492eab7bec109d") {
        setRebookCoupon("1KRYHEMOUFVN"); // 5%
        setRebookDiscountPercentage(0.05);
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "2f206aa4d52f82d7ee10d01300aa59b7") {
        setRebookCoupon("DXDJJQDVJJID"); // 10%
        setRebookDiscountPercentage(0.1);
        setRebookCouponReady(true);
        return;
      }
      const response = await getCheckOutById(rebookValue);
      const data = await response.json();
      //console.log('rebook data', data);

      if (data && data.checkoutDate) {
        const rebookCheckOutDate = data.checkoutDate; // Date String
        const timeDifference =
          new Date().getTime() - new Date(rebookCheckOutDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        if (daysDifference < 1) {
          setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
          setRebookDiscountPercentage(0.12);
          setRebookCouponReady(true);
        } else if (daysDifference <= 14) {
          setRebookCoupon("DXDJJQDVJJID"); // 10%
          setRebookDiscountPercentage(0.1);
          setRebookCouponReady(true);
        } else {
          setRebookCoupon("1KRYHEMOUFVN"); // 5%
          setRebookDiscountPercentage(0.05);
          setRebookCouponReady(true);
        }
      }
      setRebookCouponReady(true);
    };

    // If rebookParameter and rebookMode are set, get the checkout date and coupon code
    if (rebookValue && isRebook) {
      getRebookCheckoutDateAndCoupon();
    }
    //console.log('rebookCoupon', rebookCoupon, 'rebookCouponReady', rebookCouponReady)
    setRebookCouponReady(true);
  }, [location]);

  // Search Input
  const getSearchInput = () => {
    let tags = [];

    // Add "Dog Friendly" tag if dog toggled to true
    if (dogs) {
      tags.push("Dog Friendly");
    }

    //console.log('boundingbox',boundingBox)

    const returnData = {
      city: town && !mapAreaSearch && town !== "All Towns" ? town : null,
      checkIn: startDate ? format(startDate, "yyyy-MM-dd") : null,
      checkOut: endDate ? format(endDate, "yyyy-MM-dd") : null,
      minOccupancy: guests ? guests : 1,
      tags: tags,
      bbLatitude: mapAreaSearch && boundingBox ? boundingBox.bbLatitude : null,
      bbLongitude:
        mapAreaSearch && boundingBox ? boundingBox.bbLongitude : null,
      bbOppositeLatitude:
        mapAreaSearch && boundingBox ? boundingBox.bbOppositeLatitude : null,
      bbOppositeLongitude:
        mapAreaSearch && boundingBox ? boundingBox.bbOppositeLongitude : null,
      rebookReservation: rebookReservation,
      rebookDiscount: rebookDiscountPercentage,
    };

    return returnData;
  };

  function decrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? MIN_GUESTS : prevGuests;
      return Math.max(MIN_GUESTS, Number(currentGuests) - 1);
    });
  }

  function incrementGuestInput() {
    setGuests((prevGuests) => {
      const currentGuests = isNaN(prevGuests) ? MIN_GUESTS : prevGuests;
      return Math.min(MAX_GUESTS, Number(currentGuests) + 1);
    });
  }

  function renderInputValue(guests, dogs) {
    let value = "";
    if (guests) value += guests + " Guests";

    if (dogs) {
      if (value) value += " +";
      value += " Dogs";
    }
    return value;
  }

  function getMonthShortName(monthNo) {
    const date = new Date();
    date.setMonth(monthNo - 1);
    return date.toLocaleString("en-US", { month: "short" });
  }

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true before fetching
      let response;
      //console.log("initialLoad", initialLoad)
      //console.log("fetchData window.location.pathname", window.location.pathname)

      // Fetching URL params, if any
      // (Even though it says "!initialLoad", this is what happens on the initial load of the page)
      if (!initialLoad && window.location.pathname === "/") {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        // Convert all parameter names to lowercase for case-insensitive access
        const normalizedParams = {};
        for (const [key, value] of params) {
          normalizedParams[key.toLowerCase()] = value;
        }

        let filters: any = {};
        let contextDates = [];

        filters.city = normalizedParams["town"]
          ? normalizedParams["town"][0].toUpperCase() +
          normalizedParams["town"].slice(1).toLowerCase()
          : normalizedParams["city"]
            ? normalizedParams["city"][0].toUpperCase() +
            normalizedParams["city"].slice(1).toLowerCase()
            : null;

        if (mapAreaSearch || boundingBoxMoved) {
          filters.city = null;
        }

        //console.log('normalizedParams', normalizedParams)

        if (normalizedParams["quotesearch"] === "true") {
          setSearchWithDates(true);
        } else {
          setSearchWithDates(false);
        }

        if (normalizedParams["map"] === "false") {
          setMapDisplayOnLoad(false);
        } else {
          setMapDisplayOnLoad(true);
        }

        if (normalizedParams["sort"] === "random") {
          setRandomResults(true);
          filters.random = true;
        } else {
          setRandomResults(false);
          filters.random = false;
        }

        filters.checkIn = normalizedParams["startdate"]
          ? normalizedParams["startdate"]
          : normalizedParams["checkin"]
            ? normalizedParams["checkin"]
            : normalizedParams["startdate"]
              ? normalizedParams["startdate"]
              : null;

        filters.checkOut = normalizedParams["enddate"]
          ? normalizedParams["enddate"]
          : normalizedParams["checkout"]
            ? normalizedParams["checkout"]
            : normalizedParams["enddate"]
              ? normalizedParams["enddate"]
              : null;

        filters.minOccupancy = normalizedParams["guests"]
          ? normalizedParams["guests"]
          : normalizedParams["minoccupancy"]
            ? normalizedParams["minoccupancy"]
            : null;

        filters.tags = normalizedParams["tags"]
          ? normalizedParams["tags"].split(",")
          : [];

        // Bounding box functionality
        filters.bbLatitude = boundingBox ? boundingBox.bbLatitude : null;
        filters.bbLongitude = boundingBox ? boundingBox.bbLongitude : null;
        filters.bbOppositeLatitude = boundingBox
          ? boundingBox.bbOppositeLatitude
          : null;
        filters.bbOppositeLongitude = boundingBox
          ? boundingBox.bbOppositeLongitude
          : null;

        let tabTitle = "Del Mar Vacations";

        if (filters.city) {
          tabTitle = tabTitle + " | " + filters.city;
        }

        if (filters.checkIn && filters.checkOut) {
          let dateTitleString =
            getMonthShortName(filters.checkIn.split("-")[1]) +
            " " +
            filters.checkIn.split("-")[2] +
            "-" +
            filters.checkOut.split("-")[2] +
            ", " +
            filters.checkIn.split("-")[0];
          tabTitle = tabTitle + " | " + dateTitleString;
        }

        if (filters.minOccupancy) {
          tabTitle = tabTitle + " | " + filters.minOccupancy + " Guests";
        }

        if (filters.tags.length > 0) {
          if (filters.tags.includes("Dog Friendly")) setDogs(true);
          for (let i = 0; i < filters.tags.length; i++)
            tabTitle = tabTitle + " | " + filters.tags[i];
        }

        document.title = tabTitle;

        filters.minPrice = normalizedParams["minprice"]
          ? normalizedParams["minprice"]
          : null;
        filters.maxPrice = normalizedParams["maxprice"]
          ? normalizedParams["maxprice"]
          : null;

        if (normalizedParams["numberofbathrooms"]) {
          let minBathroomsNumber = Number(
            normalizedParams["numberofbathrooms"]
          );
          filters.selectedBathrooms = [];
          filters.numberOfBathrooms = minBathroomsNumber;

          for (
            minBathroomsNumber;
            minBathroomsNumber <= 7;
            minBathroomsNumber++
          ) {
            if (minBathroomsNumber === 7) {
              filters.selectedBathrooms.push("7+");
            } else {
              filters.selectedBathrooms.push(minBathroomsNumber.toString());
            }
          }
        }

        if (normalizedParams["numberofbedrooms"]) {
          let minBedroomsNumber = Number(normalizedParams["numberofbedrooms"]);
          filters.selectedBedrooms = [];
          filters.numberOfBedrooms = minBedroomsNumber;

          for (minBedroomsNumber; minBedroomsNumber <= 7; minBedroomsNumber++) {
            if (minBedroomsNumber === 7) {
              filters.selectedBedrooms.push("7+");
            } else {
              filters.selectedBedrooms.push(minBedroomsNumber.toString());
            }
          }
        }

        setTown(filters.city);

        contextDates.push(
          filters.checkIn ? new Date(filters.checkIn + "T00:00:00") : null
        );
        contextDates.push(
          filters.checkOut ? new Date(filters.checkOut + "T00:00:00") : null
        );
        setContextDateRange(contextDates);

        setGuests(filters.minOccupancy);

        filters.rebookReservation = rebookReservation;
        filters.rebookDiscount = rebookDiscountPercentage;

        //console.log("filters sent to fetchSearchResults", filters);
        response = await fetchSearchResults(filters);

        const listings = await response.json();
        console.log('SEARCH RESULTS:', listings)
        const results = listings.results;

        results.sort((a, b) => {
          // Check if 'a' has flexSearch and 'b' does not
          if (a.flexSearch && !b.flexSearch) {
            return 1; // 'a' should come after 'b'
          }

          // Check if 'b' has flexSearch and 'a' does not
          if (b.flexSearch && !a.flexSearch) {
            return -1; // 'a' should come before 'b'
          }

          return 0; // No change in order if both have or don't have flexSearch
        });

        setFlexSearchResults(
          results.some((element) => element.hasOwnProperty("flexSearch"))
        );

        if (
          !Object.values(filters).every(
            (value) =>
              value === null || (Array.isArray(value) && value.length === 0)
          )
        ) {
          setTotalResults(results.length);
          //console.log("listings.total", listings.total)
          setPathingFilterSettings({});
          setPathingFilterSettings({});
        } else {
          setTotalResults(results.length); // Update context data with total results from backend for pagination purposes
          if (!filters.tags) filters.tags = [];
          //console.log("listings.total", listings.total)
          setPathingFilterSettings(filters);
        }

        setSearchResults(results); // Update context data with search results array of houses

        if (results.length > 0) {
          const prices = results.map((listing) => listing.price);
          setMaxSearchPriceValue(Math.round(Math.max(...prices)));
          setMinSearchPriceValue(Math.round(Math.min(...prices)));
        }

        setInitialLoad(true);

        // If this is NOT the initial load of the page (even though it says "setInitialLoad(true)" above...
      } else if (
        window.location.pathname === "/" ||
        window.location.pathname === "/search-results/" ||
        window.location.pathname === "/search-results" ||
        window.location.pathname.includes("/search-results") ||
        window.location.pathname === "/properties/" ||
        window.location.pathname === "/properties" ||
        window.location.pathname.includes("/properties") ||
        window.location.pathname === "/listings/" ||
        window.location.pathname === "/listings" ||
        window.location.pathname.includes("/listings")
      ) {
        //console.log("*** boundingBox", boundingBox)

        // If it's searchMode, use filters
        if (searchMode || boundingBox !== null) {
          //console.log("+++ IS SEARCHMODE")
          let urlParamsString = "/?";
          let tabTitle = "Del Mar Vacations";

          if (town && !mapAreaSearch) {
            urlParamsString = urlParamsString + "town=" + town;
            tabTitle = tabTitle + " | " + town;
          }

          if (startDate && endDate) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'
            const formattedEndDate = format(endDate, "yyyy-MM-dd"); // Formats as 'YYYY-MM-DD'

            if (urlParamsString === "/?") {
              urlParamsString =
                urlParamsString +
                "startdate=" +
                formattedStartDate +
                "&enddate=" +
                formattedEndDate;
            } else {
              urlParamsString =
                urlParamsString +
                "&startdate=" +
                formattedStartDate +
                "&enddate=" +
                formattedEndDate;
            }
            let dateTitleString =
              getMonthShortName(startDate.getMonth() + 1) +
              " " +
              startDate.getDate() +
              "-" +
              endDate.getDate() +
              ", " +
              startDate.getFullYear();
            tabTitle = tabTitle + " | " + dateTitleString;
          }

          if (guests) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "guests=" + guests;
            } else {
              urlParamsString = urlParamsString + "&guests=" + guests;
            }
            tabTitle = tabTitle + " | " + guests + " Guests";
          }

          if (dogs) {
            if (urlParamsString === "/?") {
              urlParamsString = urlParamsString + "tags=Dog Friendly";
            } else {
              urlParamsString = urlParamsString + "&tags=Dog Friendly";
            }
            tabTitle = tabTitle + " | Dog Friendly";
          }

          window.history.pushState({}, "", urlParamsString);
          document.title = tabTitle;
          setQueryOffset(0);
          console.log("getSearchInput()", getSearchInput());
          response = await fetchSearchResults(getSearchInput());
        } else {
          // If its NOT searchmode, don't use filters
          //console.log("--- NOT SEARCHMODE")
          response = await fetchSearchResults();
        }

        const listings = await response.json();
        console.log('listings', listings)
        const results = listings.results;

        results.sort((a, b) => {
          // Check if 'a' has flexSearch and 'b' does not
          if (a.flexSearch && !b.flexSearch) {
            return 1; // 'a' should come after 'b'
          }

          // Check if 'b' has flexSearch and 'a' does not
          if (b.flexSearch && !a.flexSearch) {
            return -1; // 'a' should come before 'b'
          }

          return 0; // No change in order if both have or don't have flexSearch
        });

        setFlexSearchResults(
          results.some((element) => element.hasOwnProperty("flexSearch"))
        );
        setTotalResults(results.length)

        setSearchResults(results); // Update context data with search results array of houses

        if (results.length > 0) {
          const prices = results.map((listing) => listing.price);
          setMaxSearchPriceValue(Math.round(Math.max(...prices)));
          setMinSearchPriceValue(Math.round(Math.min(...prices)));
        }
      }
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching (or if there's an error)
    }
  };

  const fetchAdditionalData = async () => {
    try {
      setBufferLoading(true); // Set loading to true before fetching

      let response = await fetchSearchResults({ offset: queryOffset });

      const listings = await response.json();
      //console.log('fetchAdditionalData listings', listings)
      const results = listings.results;

      setTotalResults(results.length); // Update context data with total results from backend for pagination purposes
      setSearchResults(searchResults.concat(results)); // Update context data with search results array of houses
      if (results.length > 0) {
        const prices = searchResults
          .concat(results)
          .map((listing) => listing.price);
        setMaxSearchPriceValue(Math.round(Math.max(...prices)));
        setMinSearchPriceValue(Math.round(Math.min(...prices)));
      }
    } catch (error) {
      console.error("Error fetchAdditionalData results:", error);
    } finally {
      setBufferLoading(false);
    }
  };

  React.useEffect(() => {
    //console.log('town',town)
    if (
      town in
      [
        "Barnstable",
        "Bourne",
        "Brewster",
        "Chatham",
        "Dennis",
        "Eastham",
        "Falmouth",
        "Harwich",
        "Mashpee",
        "Orleans",
        "Provincetown",
        "Sandwich",
        "Truro",
        "Wellfleet",
        "Yarmouth",
      ]
    ) {
      setMapAreaSearch(false);
      setBoundingBox(null);
      //console.log('not map search area')
    } else if (mapAreaSearch) {
      setTown(null);
    }
    //console.log('rebookDiscountPercentage', rebookDiscountPercentage)
    fetchData();

  }, [fetchKey, triggerSearch, rebookDiscountPercentage]);

  React.useEffect(() => {
    if (queryOffset > 0) fetchAdditionalData();
  }, [queryOffset]);

  // Fetch Global Calendar Availability to Restrict Date Options in Search Bar Date Picker
  const fetchGlobalCalendarAvailability = async () => {
    setGlobalCalendarLoading(true);

    try {
      let response = await fetchGlobalCalendar();
      const globalCheckInsStrings = await response.json();

      //console.log("globalCheckInsStrings",globalCheckInsStrings)

      const today = format(new Date(), "yyyy-MM-dd");

      // Filter out dates that are today or earlier
      const filteredDateStrings = globalCheckInsStrings.filter(
        (dateString) => dateString > today
      );

      // Sort the filtered date strings directly
      const sortedDateStrings = filteredDateStrings.sort((a, b) =>
        a.localeCompare(b)
      );

      // Update the state with sorted date strings
      setGlobalCheckIns(sortedDateStrings);

      // Use Global Check Ins in Datepickers if it exists
      const useGlobalCheckIns =
        sortedDateStrings && sortedDateStrings.includes("2024-07-21");

      // Conditionally prepare props based on globalCheckIns availability
      const conditionalProps = useGlobalCheckIns
        ? {
          filterDate: (d) =>
            sortedDateStrings.includes(format(d, "yyyy-MM-dd")),
          openToDate: parseISO(sortedDateStrings[0]),
        }
        : {
          includeDateIntervals: [
            { start: addDays(new Date(), 1), end: new Date(MAX_END_DATE) },
          ],
        };

      setConditionalCalendarProps(conditionalProps);
    } catch (error) {
      console.error("Error fetching global calendar availability:", error);
    } finally {
      setGlobalCalendarLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGlobalCalendarAvailability();
  }, []);

  // Favorites Handling //

  React.useEffect(() => {
    setEmailLoading(true);
    //("emailLoading", emailLoading)
    const emailFromStorage = localStorage.getItem("userEmail");
    //console.log("emailFromStorage", emailFromStorage)

    const fetchData = async () => {
      try {
        if (user && user.primaryEmailAddress.emailAddress) {
          const email = user.primaryEmailAddress.emailAddress;
          //console.log("email", email)
          setUserEmail(email);
          await fetchUserFavorites(email);
        } else if (emailFromStorage) {
          //console.log("using emailFromStorage", emailFromStorage)
          setUserEmail(emailFromStorage);
          await fetchUserFavorites(emailFromStorage);
        } else {
          setEmailLoading(false);
          setFavoritesLoading(false);
        }
      } catch (error) {
        console.error("Error in fetchUserFavorites:", error);
      }
    };

    fetchData();
    setEmailLoading(false);
  }, [user]);

  React.useEffect(() => {
    setNumFavorites(userFavorites.length);
  }, [userFavorites]);

  React.useEffect(() => {
    if (mapAreaSearch) {
      setTown(null);
    }
  }, [mapAreaSearch]);

  const fetchUserFavorites = async (email) => {
    try {
      setFavoritesLoading(true);
      const response = await getFavoritesWithHouseData(email);
      const data = await response.json();
      //console.log("Favorites:", data);

      setUserFavorites([...data]);
    } catch (error) {
      console.error("Error fetching favorites:", error);
    } finally {
      setFavoritesLoading(false); // End loading
    }
  };

  // END Favorites Handling

  if (isMobile) {
    return (
      <MobileSearch
        datesToGrayOut={datesToGrayOut}
        setTown={setTown}
        town={town}
        startDate={startDate}
        endDate={endDate}
        conditionalCalendarProps={conditionalCalendarProps}
        decrementGuestInput={decrementGuestInput}
        incrementGuestInput={incrementGuestInput}
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        closeModalAndSearch={closeModalAndSearch}
      />
    );
  }
  // NOT mobile
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"650px"}
      h={"40px"}
      ml={4}
    >
      {/* Town Dropdown */}
      <FormControl w={"30%"}>
        <Select
          value={mapAreaSearch ? "Map Area" : town || ""}
          onChange={(e) => {
            setMapAreaSearch(false);
            setTown(e.target.value || null);
          }}
          bgColor={"white"}
          color={"black"}
          fontSize={13.5}
          borderRightRadius={0}
          border="solid 1px #00000038"
        >
          <option placeholder="All Towns" value="All Towns">
            All Towns
          </option>
          {TOWNS.map((town) => (
            <option key={town} value={town}>
              {town}
            </option>
          ))}
          {mapAreaSearch && <option value="Map Area">Map Area</option>}
        </Select>
      </FormControl>
      {/* Date Picker */}
      {!globalCalendarLoading && (
        <FormControl
          w={"25%"}
          h={"100%"}
          minW={"190px"}
          fontSize={13.5}
          border="solid 1px #00000038"
          borderLeft="0"
        >
          <Flex
            color={"black"}
            height={"40px"}
            w={"100%"}
            h={"100%"}
            textAlign={"center"}
          >
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setContextDateRange(update);
              }}
              isClearable={true}
              placeholderText="Select Dates"
              h={"100%"}
              dateFormat={"M/d/yyyy"}
              textAlign="center"
              {...conditionalCalendarProps}
              excludeDates={datesToGrayOut}
              filterDate={(date) => {
                // If there is a start date, ensure that the end date is at least 2 days after
                if (startDate) {
                  return date >= addDays(startDate, 2);
                }
                return true; // Allow all dates if no start date is selected
              }}
            />
          </Flex>
        </FormControl>
      )}
      {/* Guests Number Input */}
      <Popover>
        <PopoverTrigger>
          <FormControl w={"35%"}>
            <Input
              readOnly={true}
              bgColor={"white"}
              color={"black"}
              border="solid 1px #00000038"
              borderLeft="0"
              borderRight="0"
              borderRadius={0}
              fontSize={13.5}
              placeholder={"Guests"}
              value={renderInputValue(guests, dogs)}
            />
          </FormControl>
        </PopoverTrigger>
        <PopoverContent maxW="min-content">
          <PopoverBody>
            <Grid m="10px" gap={4} templateColumns="repeat(2, 1fr)">
              <GridItem p="8px" h="10">
                <Text color="black">Guests</Text>
              </GridItem>
              <GridItem h="10">
                <HStack>
                  <Button onClick={decrementGuestInput} variant="ghost">
                    -
                  </Button>
                  <Input
                    textAlign={"center"}
                    p="10px"
                    minW="50px"
                    color="black"
                    type="number"
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value, 10);
                      if (
                        !isNaN(newValue) &&
                        newValue >= MIN_GUESTS &&
                        newValue <= MAX_GUESTS
                      ) {
                        setGuests(newValue);
                      }
                    }}
                    value={guests || ""}
                  />
                  <Button onClick={incrementGuestInput} variant="ghost">
                    +
                  </Button>
                </HStack>
              </GridItem>

              <GridItem p="8px" h="10">
                <Text color="black">Dogs</Text>
              </GridItem>
              <GridItem h="10">
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  w={"100%"}
                  h={"100%"}
                  margin={"auto 0"}
                >
                  <Switch
                    id="dogs-switch"
                    colorScheme="dmNavy"
                    isChecked={dogs}
                    onChange={(e) => setDogs(e.target.checked)}
                    size="lg"
                  />
                  <Text
                    fontSize="xs"
                    mt={2}
                    lineHeight={0}
                    color={"dmNavy.500"}
                  >
                    {dogs ? "Yes" : "No"}
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Button
        onClick={handleSearchClick}
        w={"10%"}
        borderLeftRadius={0}
        bgColor={"dmOrange.500"}
        color={"white"}
        _hover={{ bg: "dmOrange.600" }}
      >
        <FiSearch />
      </Button>
    </Flex>
  );
}

export default SearchWidget;
