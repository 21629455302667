import {
    Button,
    Checkbox,
    Flex,
    FormLabel,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from "react";

export const QuoteQuickSearchModal = ({ isOpen, onClose, onSearch, initialParams, ALLOWED_TAGS }) => {
    const [params, setParams] = useState(initialParams);

    useEffect(() => {
        if (isOpen) {
            setParams(initialParams);
        }
    }, [isOpen, initialParams]);

    const handleInputChange = (field, value) => {
        setParams({ ...params, [field]: value });
    };

    const handleTagChange = (tag) => {
        const selectedTags = new Set(params.tags ? params.tags.split(',') : []);
        if (selectedTags.has(tag)) {
            selectedTags.delete(tag);
        } else {
            selectedTags.add(tag);

            // Enforce mutual exclusivity between 'Dog Friendly' and 'No Dogs'
            if (tag === 'Dog Friendly') {
                selectedTags.delete('No Dogs');
            } else if (tag === 'No Dogs') {
                selectedTags.delete('Dog Friendly');
            }
        }
        setParams({ ...params, tags: Array.from(selectedTags).join(',') });
    };

    const handleSubmit = () => {
        const queryParams = new URLSearchParams();

        if (params.town) queryParams.set('town', params.town);
        if (params.startdate) queryParams.set('startdate', params.startdate);
        if (params.enddate) queryParams.set('enddate', params.enddate);
        if (params.tags) queryParams.set('tags', params.tags);
        if (params.guests) queryParams.set('guests', params.guests);

        onSearch(Object.fromEntries(queryParams.entries()));
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Quick Search</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormLabel htmlFor="town" mb={1}>Town</FormLabel>
                    <Select
                        placeholder="All Towns"
                        value={params.town || ''}
                        onChange={(e) => handleInputChange('town', e.target.value)}
                    >
                        <option value="Barnstable">Barnstable</option>
                        <option value="Bourne">Bourne</option>
                        <option value="Brewster">Brewster</option>
                        <option value="Chatham">Chatham</option>
                        <option value="Dennis">Dennis</option>
                        <option value="Eastham">Eastham</option>
                        <option value="Falmouth">Falmouth</option>
                        <option value="Harwich">Harwich</option>
                        <option value="Mashpee">Mashpee</option>
                        <option value="Orleans">Orleans</option>
                        <option value="Provincetown">Provincetown</option>
                        <option value="Sandwich">Sandwich</option>
                        <option value="Truro">Truro</option>
                        <option value="Wellfleet">Wellfleet</option>
                        <option value="Yarmouth">Yarmouth</option>
                    </Select>
                    <FormLabel htmlFor="startdate" mt={4} mb={1}>Dates (YYYY-MM-DD)</FormLabel>
                    <Flex gap={2}>
                        <Input
                            type="text"
                            placeholder="Checkin Date (YYYY-MM-DD)"
                            value={params.startdate || ''}
                            onChange={(e) => handleInputChange('startdate', e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="Checkout Date (YYYY-MM-DD)"
                            value={params.enddate || ''}
                            onChange={(e) => handleInputChange('enddate', e.target.value)}
                        />
                    </Flex>
                    <FormLabel htmlFor="guests" mt={4} mb={1}>Guests</FormLabel>
                    <Input
                        type="number"
                        placeholder="# of Guests"
                        value={params.guests || 2}
                        onChange={(e) => handleInputChange('guests', e.target.value)}
                    />
                    <FormLabel htmlFor="tags" mt={4} mb={1}>Listing Amenities</FormLabel>
                    <VStack align="start">
                        <HStack wrap="wrap">
                            {ALLOWED_TAGS.map((tag) => (
                                <Checkbox
                                    key={tag}
                                    isChecked={(params.tags || '').split(',').includes(tag)}
                                    onChange={() => handleTagChange(tag)}
                                >
                                    {tag}
                                </Checkbox>
                            ))}
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="dmOrange" mr={3} onClick={handleSubmit}>
                        Search
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};