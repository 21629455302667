import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { getChannelDisplayName } from "../../helpers/helpers";
import { fetchReceipts } from "../../helpers/reservationService";

const Receipts = ({ reservationNumber }) => {
  const [receipts, setReceipts] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!reservationNumber) return;

    const loadReceipts = async () => {
      setIsLoading(true);
      await fetchReceipts(reservationNumber, setReceipts, setErrorMessage);
      setIsLoading(false);
    };

    loadReceipts();
  }, [reservationNumber]);

  const sortReceipts = (receipts) => {
    const reservation = receipts.filter((r) => r.type === "Reservation");
    const stripeDogFee = receipts.filter((r) => r.type === "Dog Fee");
    const stripeCleanerTip = receipts.filter((r) => r.type === "Cleaner Tip");
    const shopify = receipts.filter((r) => r.type === "Add-On Purchase");

    return [...reservation, ...stripeDogFee, ...stripeCleanerTip, ...shopify];
  };

  const handlePrint = () => {
    const printContent = printRef.current;
    if (!printContent) {
      console.error("Print content is not available.");
      return;
    }

    // Backup the original page content
    const originalContent = document.body.innerHTML;

    // Replace the page content with the print content
    document.body.innerHTML = `<div>${printContent.innerHTML}</div>`;

    // Trigger the print dialog
    window.print();

    // Restore the original page content
    document.body.innerHTML = originalContent;

    // Rehydrate scripts and styles (optional, if dynamic content needs to reload)
    window.location.reload();
  };



  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (errorMessage) {
    return (
      <Flex direction="column" align="center" justify="center">
        <Text color="red.500" fontSize="lg">
          {errorMessage}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" alignItems="center" >
      <Flex pb={2} w={'100%'} justifyContent={'space-between'}>
        <Button colorScheme={"dmOrange"} onClick={handlePrint} mb={4}>
          Print Page
        </Button>
      </Flex>
      {sortReceipts.length > 0 ? (
        <Flex direction="column" width="100%" ref={printRef}>
          {receipts.map((receipt, index) => (
            <Box
              key={index}
              p={4}
              mb={2}
              borderWidth="1px"
              borderRadius="md"
              shadow="sm"
            >
              <Text fontWeight="600" fontSize="md" mb={2}>
                {receipt.type} {receipt.type === 'Reservation' && `#${reservationNumber}`}
              </Text>
              <Text fontWeight="600" fontSize="sm">
                Status: {receipt.status || "Confirmed"}
              </Text>
              {!receipt.source || ['manual', 'direct', 'website', 'be-api', 'sta'].includes(receipt.source.toLowerCase()) ? (
                <>
                  <Text fontSize="sm">
                    Amount: {receipt.totalPrice || "N/A"}
                  </Text>
                  {receipt.totalRefunded && (
                    <Text fontSize="sm">
                      Total Refunded: {receipt.totalRefunded}
                    </Text>
                  )}
                  <Text fontSize="sm">
                    Purchase Date: {receipt.date || "N/A"}
                  </Text>
                  {receipt.lineItems && (
                    <>
                      <Text fontWeight="600" fontSize="sm" mt={2}>Line Items:</Text>
                      {receipt.lineItems.map((item, itemIndex) => (
                        <Box key={itemIndex} pl={4}>
                          <Text fontSize="sm">
                            - {item.title} {item.quantity && `(x${item.quantity})`} - {item.amount}
                          </Text>
                        </Box>
                      ))}
                    </>
                  )}
                  {receipt.payments && (
                    <>
                      <Text fontWeight="600" fontSize="sm" mt={2}>Payments:</Text>
                      {receipt.payments.map((payment, paymentIndex) => (
                        <Box key={paymentIndex} pl={4}>
                          <Text fontSize="sm">
                            {payment.amount} - {payment.date} - {payment.status}
                          </Text>
                        </Box>
                      ))}
                    </>
                  )}
                  {receipt.receiptUrl && (
                    <Text fontSize="sm" _hover={{ textDecoration: 'underline' }} color={'dmOrange.500'} mt={2}>
                      <a href={receipt.receiptUrl} target="_blank" rel="noopener noreferrer">
                        View Receipt
                      </a>
                    </Text>
                  )}
                </>
              )
                :
                <Text>Your reservation was made via {getChannelDisplayName(receipt.source)}. Please refer to them for your receipt.</Text>
              }
            </Box>
          ))}
        </Flex>
      ) : (
        <Text>No receipts found for this reservation.</Text>
      )}
    </Flex>
  );
};

export { Receipts };

