import Cookies from "js-cookie";
import config from "../config";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;


export const fetchTowns = () => {
  return fetch(`${API_ENDPOINT}/towns`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};


/* Search returns results when there ARE filters to search, and defaults when there are none */
type SearchInput = {
  [key: string]: any;
  random?: boolean;
};

export const fetchSearchResults = (input: SearchInput = {}) => {
  //console.log("fetchSearchResults input", input);

  //input.random = true;

  // Filter out properties with value null or "null" and ensure all values are strings
  const filteredInput = Object.fromEntries(
    Object.entries(input)
      .filter(([key, value]) => value !== null && value !== "null")
      .map(([key, value]) => [key, String(value)]) // Convert all values to strings
  );

  // Check if the filtered input object is empty
  const isInputEmpty = Object.keys(filteredInput).length === 0;

  // If input is not empty, convert it into a query string
  const queryString = isInputEmpty
    ? ""
    : `?${new URLSearchParams(
      filteredInput as Record<string, string>
    ).toString()}`;
  //console.log("queryString", queryString);

  // Use the query string in the fetch request, if it's not empty
  return fetch(`${API_ENDPOINT}/listings/searchV2${queryString}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchHouseDetails = (params) => {
  const queryString = new URLSearchParams(params).toString();
  //console.log("making fetch house details call");
  return fetch(`${API_ENDPOINT}/listings/getHouseDetails?${queryString}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchHouseCalendar = (houseId, startDate, endDate) => {
  const queryString = new URLSearchParams({ startDate, endDate }).toString();
  //console.log("making fetch house calendar call", queryString);
  return fetch(
    `${API_ENDPOINT}/houses/${houseId}/availability?${queryString}`,
    {
      method: "GET",
      headers: defaultHeaders(),
    }
  );
};

export const getReservationQuote = (params) => {
  const queryString = new URLSearchParams(params).toString();
  //console.log("making reservation quote call", queryString);
  return fetch(
    `${API_ENDPOINT}/houses/generateReservationQuote?${queryString}`,
    {
      method: "POST",
      headers: defaultHeaders(),
    }
  );
};

export const getRebookReservationQuote = (params) => {
  const queryString = new URLSearchParams(params).toString();
  //console.log("making reservation quote call", queryString);
  return fetch(
    `${API_ENDPOINT}/houses/generateReservationQuoteCustom?${queryString}`,
    {
      method: "POST",
      headers: defaultHeaders(),
    }
  );
};

export const createInstantReservation = async (
  quoteId,
  ratePlanId,
  ccToken,
  guest
) => {
  try {
    // Construct the request body
    const requestBody = JSON.stringify({
      quoteId,
      ratePlanId,
      ccToken,
      guest,
    });

    // Perform the fetch request
    const response = await fetch(
      `${API_ENDPOINT}/houses/createInstantReservationFromQuote`,
      {
        method: "POST",
        headers: {
          // Assuming defaultHeaders() returns the necessary headers including 'Content-Type' and 'Authorization'
          ...defaultHeaders(),
          Accept: "application/json; charset=utf-8",
        },
        body: requestBody,
      }
    );

    if (!response.ok) {
      // Handle HTTP errors
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse and return the JSON response
    return await response.json();
  } catch (error) {
    console.error("Error creating instant reservation:", error);
    return {
      error: {
        message: error.message || "Failed to create instant reservation",
      },
    };
  }
};

export const fetchReservationData = (reservationNumber) => {
  return fetch(`${API_ENDPOINT}/reservations/${reservationNumber}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const fetchReservationDataByHouse = async (houseNumber: string, hash: string) => {
  const response = await fetch(`${API_ENDPOINT}/houses/${houseNumber}/reservations/current`, {
    method: "GET",
    headers: defaultHeaders(),
  });

  return response.json();
};

export const fetchGuestsByUserIdAndReservationId = (data) => {
  return fetch(`${API_ENDPOINT}/guests/`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(data),
  });
};

export const fetchConfirmationSignIn = async (clerkSearchQuery) => {
  const apiBody = {
    clerkSearchQuery: clerkSearchQuery,
  };

  const clerkData = await fetch(`${API_ENDPOINT}/clerk/confirmationSignIn`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(apiBody),
  });

  return clerkData.json();
};

export const fetchClerkSessions = async (clientId, userId) => {
  const apiBody = {
    clientId: clientId,
    userId: userId,
  };

  const clerkData = await fetch(`${API_ENDPOINT}/clerk/sessions`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(apiBody),
  });

  return clerkData.json();
};

export const getFavorites = (email) => {
  //console.log("getting favorites for", email);

  const queryString = new URLSearchParams({ email }).toString();

  return fetch(`${API_ENDPOINT}/userPreferences/getFavorites?${queryString}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const getFavoritesWithHouseData = (email) => {
  //console.log("getting favorites with house data for", email);

  const queryString = new URLSearchParams({ email }).toString();

  return fetch(`${API_ENDPOINT}/userPreferences/getFavourites?${queryString}`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};

export const setFavorite = (houseNumber, email) => {
  //console.log("setting favorite", houseNumber, email);

  return fetch(`${API_ENDPOINT}/userPreferences/setFavorite`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ houseNumber, email }),
  });
};

export const removeFavorite = (houseNumber, email) => {
  //console.log("removing favorite", houseNumber, email);

  return fetch(`${API_ENDPOINT}/userPreferences/removeFavorite`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ houseNumber, email }),
  });
};

export const submitBookingHelpForm = (formData) => {
  // Filter out keys with empty string values
  const filteredFormData = Object.fromEntries(
    Object.entries(formData).filter(([key, value]) => value !== "")
  );

  //console.log("Submitting booking help form", filteredFormData);

  return fetch(`${API_ENDPOINT}/hsforms/handleBookingHelpForm`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(filteredFormData),
  });
};

export const createDogPaymentIntent = (paymentData) => {
  return fetch(`${API_ENDPOINT}/stripe/createDogPaymentIntent`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(paymentData),
  });
};

export const setUserShare = (houseNumber, email) => {
  return fetch(`${API_ENDPOINT}/userPreferences/setShare`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify({ houseNumber, email }),
  });
};

export const createTipPaymentIntent = (paymentData) => {
  return fetch(`${API_ENDPOINT}/stripe/createTipPaymentIntent`, {
    method: "POST",
    headers: defaultHeaders(),
    body: JSON.stringify(paymentData),
  });
};

export const fetchBanners = () => {
  return fetch(`${API_ENDPOINT}/banners`, {
    method: "GET",
    headers: defaultHeaders(),
  });
};