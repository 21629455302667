import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack, Text,
  Textarea, useToast
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useTrips } from "../../contexts/TripContext";
import { handleServiceRequest } from "../../helpers/guestportalfetch";
import { omitNullAndUndefined } from "../../helpers/helpers";
import { sendHubspotEvent } from "../../helpers/hubspot";

type Props = {
  contactOwner: any;
  userEmail: string;
  onPopoverClose: Function;
  houseNumber?: string;
  reservationNumber?: string;
};

const GuestServicesPopOver: React.FC<Props> = ({
  contactOwner,
  userEmail,
  onPopoverClose,
  houseNumber: propHouseNumber = "",
  reservationNumber: propReservationNumber = "",
}) => {

  const [formOpen, setFormState] = React.useState(false);
  const [formType, setFormType] = React.useState("service");
  const [houseNumber, setHouseNumber] = React.useState(propHouseNumber);
  const [reservationNumber, setReservationNumber] = React.useState(propReservationNumber);
  const [placeholderText, setPlaceholderText] = React.useState("");
  const [formHeaderText, setFormHeaderText] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const toast = useToast()

  const { selectedTrip, isTripsContextLoaded } = useTrips();
  //console.log("selectedTrip",selectedTrip,"isTripsContextLoaded",isTripsContextLoaded)
  //console.log("contactOwner",contactOwner)

  React.useEffect(() => {
    if (isTripsContextLoaded) {
      setHouseNumber(selectedTrip.houseNumber);
      setReservationNumber(selectedTrip.reservationNumber);
    }
    //console.log("selectedTrip",selectedTrip)
  }, []);


  // HubSpot Custom Event // 

  const eventFiredRef = React.useRef(false); // Track if event has been fired
  const eventName = "pe21053466_guest_portal_help_modal_opened";
  const properties = omitNullAndUndefined({
    house_number: selectedTrip.houseNumber,
    reservation_number: selectedTrip.reservationNumber,
    hs_page_url: window.location.href,
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };

  React.useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          //console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change

  // END HubSpot Custom Event // 


  function openForm(e: any) {
    let dataId = e.currentTarget.getAttribute("data-id");
    if (!dataId) {
      dataId = e.currentTarget.parent.getAttribute("data-id");
    }
    setFormType(dataId);
    switch (dataId) {
      case "service":
        setPlaceholderText("EX: The dishwasher is broken.");
        setFormHeaderText("I'm at the house and need service.");
        break;
      case "damage":
        setPlaceholderText("EX: The back door has a large dent.");
        setFormHeaderText("I want to report damage.");
        break;
      case "question":
        setPlaceholderText("How can we help?");
        setFormHeaderText("I have a question or concern.");
        break;
      default:
        break;
    }
    setFormState(true);
  }

  function closeForm() {
    setFormState(false);
  }

  const rebuildData = (values) => {
    let formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });
    return formData;
  };

  return (
    <Box>
      <Collapse in={!formOpen}>
        <Box id="services-form-closed" className="services-form">
          <Flex
            alignItems={"center"}
            width={"100%"}
            justifyContent={"center"}
            pt={8}
            textColor="black"
          >
            <SimpleGrid
              w={{ base: "100%" }}
              maxW={"500px"}
              columns={{ base: 1 }}
            >
              <Flex
                flexDirection={{ base: "row" }}
                justifyContent={"center"}
                alignItems={"center"}
                bg={{ base: "white" }}
                py={{ base: 6 }}
              >
                <Box pt={{ base: 0 }} pl={{ base: 8 }}>
                  <Avatar
                    bg="gray.200"
                    size={"2xl"}
                    name={contactOwner.name}
                    src={contactOwner.avatar}
                  />{" "}
                </Box>
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  px={{ base: 4 }}
                >
                  <Box
                    pt={{ base: 0 }}
                    pb={1}
                    fontSize={"xl"}
                    w={"100%"}
                    textAlign={{ base: "left" }}
                  >
                    {contactOwner.name}
                  </Box>
                  <Box
                    pb={{ base: 0 }}
                    fontSize={"md"}
                    w={"100%"}
                    textAlign={{ base: "left" }}
                  >
                    {contactOwner.title}
                  </Box>
                </Flex>
              </Flex>
              <Flex
                flexDirection={"column"}
                justifyContent={"flex-start"}
                textAlign={"left"}
                pb={8}
                pr={12}
                pl={{ base: 8 }}
                pt={{ base: 2 }}
                bg={{ base: "white" }}
              >
                <Box
                  pt={{ base: 0 }}
                  pb={5}
                  fontSize={"3xl"}
                  fontWeight={"bold"}
                >
                  How can we help you today?

                </Box>
                <Link data-id="service" onClick={(e) => openForm(e)}>
                  <Flex
                    data-id="service"
                    py={3}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Box data-id="service">
                      I'm at the house and need service
                    </Box>
                    <Box data-id="service">
                      <BsArrowRight
                        data-id="service"
                        size={36}
                        color={"#e95037"}
                      />
                    </Box>
                  </Flex>
                </Link>
                <Link data-id="damage" onClick={(e) => openForm(e)}>
                  <Flex
                    data-id="damage"
                    py={3}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Box data-id="damage">I want to report damage</Box>
                    <Box data-id="damage">
                      <BsArrowRight
                        data-id="damage"
                        size={36}
                        color={"#e95037"}
                      />
                    </Box>
                  </Flex>
                </Link>
                <Link data-id="question" onClick={(e) => openForm(e)}>
                  <Flex
                    data-id="question"
                    py={3}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Box data-id="question">I have a question or concern</Box>
                    <Box data-id="question">
                      <BsArrowRight
                        data-id="question"
                        size={36}
                        color={"#e95037"}
                      />
                    </Box>
                  </Flex>
                </Link>
              </Flex>
            </SimpleGrid>
          </Flex>
          <Flex alignItems={"center"} width={"100%"} justifyContent={"center"} bg={"dmNavy.500"}>
            <SimpleGrid
              w={{ base: "100%" }}
              maxW={"500px"}
              bg={"dmNavy.500"}
              color="white"
              columns={{ base: 1 }}
            >
              <Flex
                flexDirection={"column"}
                justifyContent={"flex-start"}
                textAlign={"left"}
              >
                <Box px={8} pt={8} fontSize={"xl"} fontWeight={"bold"}>
                  Emergency Services
                </Box>
                <Box px={8} pt={4} pb={8} fontSize={"md"}>
                  For life threatening hazards, please exit your home, make sure
                  you are safe, and call 911. Then, call our 24/7 emergency line
                  508-271-7603.
                </Box>
              </Flex>
              <Flex
                flexDirection={"column"}
                justifyContent={"flex-start"}
                textAlign={"left"}
              >
                <Box
                  pr={8}
                  pl={{ base: 8 }}
                  pt={{ base: 2 }}
                  fontSize={"xl"}
                  fontWeight={"bold"}
                >
                  Urgent Services
                </Box>
                <Box pr={8} pl={{ base: 8 }} pt={4} pb={8} fontSize={"md"}>
                  Locked out? Loss of power? Water leak? For any safety issues
                  call our 24/7 emergency line 508-271-7603 and we will send
                  someone out ASAP.
                </Box>
              </Flex>
            </SimpleGrid>
          </Flex>
        </Box>
      </Collapse>
      <Collapse in={formOpen}>
        <Box
          id="services-form-open"
          className="services-form"
          borderRadius={"8px"}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"center"}
            pt={8}
            textColor="black"
          >
            <SimpleGrid
              w={{ base: "100%" }}
              maxW={"500px"}
              columns={{ base: 1 }}
              bg={"white"}
              className="boxy"
            >

              <Flex
                flexDirection={{ base: "column" }}
                justifyContent={"center"}
                alignItems={"center"}
                bg={{ base: "white" }}
                py={{ base: 0 }}
                mx={8}
                my={{ base: 4 }}
              >
                <Flex
                  flexDirection={{ base: "row" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bg={{ base: "white" }}
                  py={{ base: 6 }}
                  display={{ base: "none" }}
                >
                  <Box pt={{ base: 0 }}>
                    <Avatar
                      bg="gray.200"
                      size="48"
                      name={contactOwner.name}
                      src={contactOwner.avatar}
                    />{" "}
                  </Box>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    pl={{ base: 6 }}
                    pt={{ base: 0 }}
                  >
                    <Box
                      pt={{ base: 0 }}
                      pb={1}
                      fontSize={{ base: "lg" }}
                      w={"100%"}
                      textAlign={{ base: "left" }}
                    >
                      {contactOwner.name}
                    </Box>
                    <Box
                      pb={{ base: 0 }}
                      fontSize={"lg"}
                      w={"100%"}
                      textAlign={{ base: "left" }}
                    >
                      {contactOwner.title}
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  width={"100%"}
                  alignSelf={"left"}
                  alignItems={"center"}
                  pt={0}
                >
                  <Link onClick={closeForm}>
                    <BsArrowLeft size={64} color={"#e95037"} />
                  </Link>
                  <Link onClick={closeForm}>
                    <Box textAlign={"left"} fontSize={{ base: "xl" }} pl={3}>
                      {formHeaderText}
                    </Box>
                  </Link>
                </Flex>
                <Box
                  textAlign={"left"}
                  pt={{ base: 4 }}
                  pb={{ base: 0 }}
                  fontSize={{ base: "md" }}
                >
                  Locked out? Loss of power? Water leak? For any non-life
                  threatening safety issues call our{" "}
                  <b>24/7 emergency line 508-271-7603</b> and we will send
                  someone out ASAP.
                </Box>
              </Flex>

              <Flex px={8} py={{ base: 0 }} bg={"white"}>
                <Formik
                  initialValues={{
                    description: "",
                    urgency: "",
                    file: "",
                    formType: formType,
                    houseNumber: houseNumber,
                    reservationNumber: reservationNumber
                  }}
                  validate={values => {
                    const errors: any = {};
                    if (!values.description) {
                      errors.description = 'Required field.';
                    }
                    if (!values.urgency && formType === 'service') {
                      errors.urgency = 'Required field.';
                    }
                    // Add more validation logic here if needed
                    return errors;
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    values.formType = formType;
                    values.houseNumber = houseNumber;
                    values.reservationNumber = reservationNumber;
                    const data = rebuildData(values);
                    //console.log("Data",values)
                    handleServiceRequest(data).then((response) => {
                      setSubmitted(true);
                      toast({
                        title: 'Thank you for submitting.',
                        description: "We'll get back to you shortly.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                        position: 'top',
                      });
                      onPopoverClose();
                      closeForm();
                      resetForm();
                    });
                  }}
                >
                  {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <form onSubmit={handleSubmit} className="guest-form">
                      <Flex
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        minH={"300px"}
                      >
                        <Textarea
                          id="description"
                          name="description"
                          variant="outline"
                          bg={"whiteAlpha.700"}
                          size="lg"
                          height={{ base: "100px" }}
                          maxLength={300}
                          placeholder={placeholderText}
                          onChange={handleChange}
                          value={values.description}
                        />
                        <Text color='red'>{errors.description && touched.description && errors.description}</Text>

                        {/* Restructured RadioGroup for Accessibility Rules */}
                        {formType === "service" && (
                          <FormControl as="fieldset">
                            <FormLabel as="legend">Urgency</FormLabel>
                            <RadioGroup
                              id="urgency"
                              name="urgency"
                              value={values.urgency}
                              onChange={(value: string) => setFieldValue('urgency', value)}
                            >
                              <Stack direction="column">
                                <Radio value="today">
                                  Today
                                </Radio>
                                <Radio value="during">
                                  During my stay
                                </Radio>
                                <Radio value="after">
                                  After my stay
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </FormControl>
                        )}
                        <Text color='red' pt={1}>{errors.urgency && touched.urgency && errors.urgency}</Text>
                        <Flex alignItems={"left "} pt={4} direction={'column'}>
                          <Box fontSize={14} pr={3} textAlign={"left"}>
                            Please upload a photo or a 5-second video. Maximum
                            file size is 32MB.
                          </Box>
                          <Box pt={2}>
                            <input
                              type="file"
                              accept="image/*,video/*"
                              name="file"
                              id="file"
                              className="input-blue-button"
                              onChange={(event) => {
                                //console.log(event.currentTarget.files)
                                setFieldValue("file", event.currentTarget.files[0]);
                              }}
                            />
                          </Box>

                        </Flex>

                        <Flex
                          justifyContent={"end"}
                          pt={{ base: 4 }}
                          pb={8}
                          w={"100%"}
                        >
                          <Button
                            className="dm-button-orange-outline"
                            type="button"
                            width="90px"
                            mr={2}
                            onClick={closeForm}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            width="90px"
                            className="dm-button-orange-solid support-form-submit"
                            color={"white"}
                          >
                            Send
                          </Button>
                        </Flex>
                      </Flex>
                    </form>
                  )}
                </Formik>
              </Flex>
            </SimpleGrid>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
};

export { GuestServicesPopOver };

