import { Button, Flex, FormLabel, Input, Link, Text } from "@chakra-ui/react";
import { useSignIn } from "@clerk/clerk-react";
import { useState } from "react";
import * as Yup from 'yup';
import config from "../../config";

// Confirmation Home Page for Redirect
const homePage = config.homePage;

// Yup schema for validation
const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export default function ClerkSignInEmail({ email, reservationId }) {
  const { isLoaded, signIn, setActive } = useSignIn();
  const [emailAddress, setEmailAddress] = useState(email || "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const returnToConfirmation = async () => {
    //console.log(`----- /confirmation/${reservationId}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message on new submission

    // Validate inputs before attempting to sign in
    try {
      await validationSchema.validate({ emailAddress, password });
    } catch (validationError) {
      setErrorMessage(validationError.message);
      return;
    }

    if (!isLoaded) {
      return;
    }

    try {
      const result = await signIn.create({
        identifier: emailAddress,
        password,
      });

      if (result.status === "complete") {
        //console.log(result);
        await setActive({ session: result.createdSessionId });
        returnToConfirmation();
      } else {
        // Handle incomplete status
        //console.log(result);
        setErrorMessage("Login incomplete. Please check your credentials.");
      }
    } catch (err) {
      console.error("error", err.errors[0].longMessage);
      setErrorMessage(err.errors[0].longMessage);
    }
  };

  return (
    <Flex as="form" w={'100%'} maxW={'350px'} gap={3} flexDirection={'column'}>
      {errorMessage && <Text fontWeight={'500'} color="red.500" mb={2}>{errorMessage}</Text>}
      <Flex w={'100%'} justifyContent={'center'} flexDirection={'column'}>
        <FormLabel textAlign={'left'} w={'100%'} mb={1} htmlFor="email">Email</FormLabel>
        <Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} id="email" name="email" type="email" />
      </Flex>
      <Flex w={'100%'} justifyContent={'center'} flexDirection={'column'}>
        <FormLabel textAlign={'left'} w={'100%'} mb={1} htmlFor="password">Password</FormLabel>
        <Input autoFocus onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" />
      </Flex>
      <Button onClick={handleSubmit} type="submit" h={'40px'} w={'100%'} colorScheme="dmOrange">Sign In</Button>
      <Link textAlign={'center'} fontSize={'0.7em'} href={`/forgot-password?redirect=${homePage}${reservationId}`}>Forgot password?</Link>
    </Flex>
  );
}
