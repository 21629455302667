import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton, AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex, IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";

import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { omitNullAndUndefined } from "../../helpers/helpers";
import { sendHubspotEvent } from "../../helpers/hubspot";
import pdfImage from '../../media/pdf.png';

function convertToArray(data: string | null): string[] {
  // Check if data exists and is not just an empty string
  if (!data || data.trim() === '' || data.trim().toLowerCase() === 'n/a' || data.trim().toLowerCase() === 'na') return [];
  // Split by semicolon and trim each result to remove extra whitespace
  return data.split(',').map(photo => photo.trim());
}

type HouseAccordionItemProps = {
  accordionTitle: string;
  accordionText: string;
  photos: string[];
  handleClickImage: (photo: string, title: string) => void;
};

const HouseAccordionItem: React.FC<HouseAccordionItemProps> = ({
  accordionTitle,
  accordionText,
  photos,
  handleClickImage,
}) => {
  return (
    <AccordionItem
      w={{ base: '100%' }}
    >
      <h2>
        <AccordionButton>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize={18}
            fontWeight={"500"}
            mt={2}
          >
            {accordionTitle}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} textAlign="left" fontSize={16} mb={4} lineHeight={8}>
        {accordionText}
        <Flex justifyContent={'flex-start'} w={'100%'} flexWrap={'wrap'}>
          {photos.map((photo, index) => (
            photo.endsWith(".pdf") ? (
              <Link key={index} href={photo} isExternal display="flex" alignItems="center" width={'100%'}>
                <Image src={pdfImage} height="50px" mt={3} mr={3} />
                <Text as="span" fontWeight={'bold'}>{accordionTitle}</Text>
              </Link>
            ) : (
              <Image
                key={index}
                src={photo}
                onClick={() => handleClickImage(photo, accordionTitle)}
                boxSize="140px"
                objectFit="cover"
                mt={3} mr={3}
                cursor={'pointer'}
              />
            )
          ))}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};

const HowThisHomeWorks = ({
  reservationData
}) => {

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;

  const eventFiredRef = React.useRef(false); // Track if event has been fired

  const eventName = "pe21053466_home_guide_clicks";
  const properties = omitNullAndUndefined({
    house_number: reservationData.houseNumber,
    reservation_number: reservationData.reservationNumber,
    hs_page_url: window.location.href,
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };

  React.useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          //console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change

  const [modalTitle, setModalTitle] = React.useState("");
  const houseData = reservationData['house']

  const accordionLabels = {
    wifiTv: "Wifi Instructions",
    laundry: "Laundry Instructions",
    trash: "Trash Instructions", // trashPhotos
    additionalAmenity: "Additional Amenities", //additionalAmenityPhotos
    misc: "Other", // houseRulesPhotos
    pathToWater: "Path to Water", // pathToWaterPhotos
    networkEquipmentLocations: "Networking Equipment Locations", // networkEquipmentPhotos
    dishwasher: "Dishwasher", // dishwasherPhotos
    ovenStove: "Oven/Stove", // ovenStovePhotos
    washerDryer: "Washer/Dryer", // washerDryerPhotos
    grill: "Grill", // grillPhotos
    coffeeMaker: "Coffee Maker", // coffeeMakerPhotos
    iceMaker: "Ice Maker", // iceMakerPhotos
    refrigerator: "Refrigerator", // refrigeratorPhotos
    electricPanel: "Electric Panel Instructions", // electricPanelInstructions
    thermostat: "Thermostat Instructions", // thermostatPhotos
    fireplace: "Fireplace", // fireplacePhotos
    additionalAccess: "Additional Access", // additionalAccessPhotos
    watercraft: "Watercraft", // watercraftPhotos
    firePit: "Fire Pit", // firePitPhotos
    pool: "Pool", // poolPhotos
    hotTub: "Hot Tub", // hotTubPhotos
    tv: "TV Instructions", // tvPhotos
    additionalElectronics: "Additional Electronics", // additionalElectronicPhotos
    uniqueLocation: "Unique Location", // uniqueLocationPhotos
    ac: "Air Conditioning", // acPhotos
    heat: "Heating", // heatPhotos
  };

  const photoCategoryMapping = {
    trash: 'trashPhotos',
    additionalAmenity: 'additionalAmenityPhotos',
    misc: 'houseRulesPhotos',
    pathToWater: 'pathToWaterPhotos',
    networkEquipmentLocations: 'networkEquipmentPhotos',
    dishwasher: 'dishwasherPhotos',
    ovenStove: 'ovenStovePhotos',
    washerDryer: 'washerDryerPhotos',
    grill: 'grillPhotos',
    coffeeMaker: 'coffeeMakerPhotos',
    iceMaker: 'iceMakerPhotos',
    refrigerator: 'refrigeratorPhotos',
    electricPanel: 'electricPanelInstructions',
    thermostat: 'thermostatPhotos',
    fireplace: 'fireplacePhotos',
    additionalAccess: 'additionalAccessPhotos',
    watercraft: 'watercraftPhotos',
    firePit: 'firePitPhotos',
    pool: 'poolPhotos',
    hotTub: 'hotTubPhotos',
    tv: 'tvPhotos',
    additionalElectronics: 'additionalElectronicPhotos',
    uniqueLocation: 'uniqueLocationPhotos',
    ac: 'acPhotos',
    heat: 'heatPhotos',
  };

  const itemsToShow = Object.keys(accordionLabels);

  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  const [currentPhotos, setCurrentPhotos] = React.useState<string[]>([]);

  const handleClickImage = (photo: string, photos: string[]) => {
    setCurrentPhotos(photos); // Store the current set of photos related to the clicked image
    const index = photos.indexOf(photo); // Find the index of the clicked photo in the current set
    if (index !== -1) {
      setSelectedImageIndex(index);
      onImageOpen();
    }
  };

  const handleNextImage = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % currentPhotos.length);
  };

  const handlePreviousImage = () => {
    setSelectedImageIndex((selectedImageIndex + currentPhotos.length - 1) % currentPhotos.length);
  };

  const accordionItems = Object.keys(houseData).filter((key) => itemsToShow.includes(key)).map((key) => {
    // Retrieve accordion text. Ensure it's treated as a string, using an empty string as a fallback.
    const accordionText = houseData[key] || "";

    // Process the photo field for the current key, if it exists, using convertToArray
    const photoFieldKey = photoCategoryMapping[key]; // Get the corresponding photo field name from the mapping
    const photos = photoFieldKey ? convertToArray(houseData[photoFieldKey]) : [];

    // Conditionally render the HouseAccordionItem only if there's text or photos
    if ((accordionText.trim() !== '' && accordionText.trim().toLowerCase() !== 'n/a' && accordionText.trim().toLowerCase() !== 'na') || photos.length > 0) {
      return (
        <HouseAccordionItem
          key={key}
          accordionTitle={accordionLabels[key]}
          accordionText={accordionText}
          photos={photos}
          handleClickImage={(photo, title) => {
            setModalTitle(title);
            handleClickImage(photo, photos);
          }}
        />
      );
    } else {
      return null;
    }
  }).filter(Boolean); // Filter out any null values

  const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();

  return (
    <Box textAlign="center" fontSize="xl" bg={"white"} w={'100%'}>

      <Center pb={{ base: 5 }}>
        <Flex bg={"white"} width={"100%"} justifyContent={"center"} py={{ base: 7, md: 10 }}>
          <Box w={'100%'}>
            <Accordion
              allowMultiple
              maxW={"1440px"}
              width={{ base: "100%" }}
            >
              {accordionItems}
            </Accordion>
          </Box>
        </Flex>
      </Center>

      <Modal isOpen={isImageOpen} onClose={onImageClose}>
        <ModalOverlay />
        <ModalContent pb={{ base: 0, md: 3 }} maxW={{ base: '100vw', md: '90vw' }} maxH={{ base: '100vh', md: '90vh' }}>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody w={'auto'} maxW={{ base: '100vw', md: '90vw' }} maxH={{ base: '100vh', md: '90vh' }} px={{ base: "1vw" }} pb={{ base: "1vw" }}>

            <Image src={currentPhotos[selectedImageIndex]} m={"0 auto"} maxW={{ base: '98vw', md: '80vw' }} maxH={{ base: '90vh', md: '80vh' }} />

            <Flex position="absolute" top="0" bottom="0" left="0" right="0" h={{ base: "calc(100% - 40px)", md: "calc(100% - 72px)" }} my={"36px"} alignItems="center" justifyContent="space-between">
              <Flex direction="column" justify="center" alignItems='center' onClick={handlePreviousImage} h={{ base: "90%" }} w={"60px"} _hover={{ backgroundColor: '#00000010' }} cursor={'pointer'} my={"36px"}>
                <IconButton
                  aria-label="Next image"
                  icon={<ArrowLeftIcon />}
                  size="lg"
                  isRound
                  color={{ base: 'white', md: 'black' }}
                  bgColor={'#00000000'}
                  _hover={{ backgroundColor: '#00000000' }}
                />
              </Flex>
              <Flex direction="column" justify="center" alignItems='center' onClick={handleNextImage} h={{ base: "90%" }} w={"60px"} _hover={{ backgroundColor: '#00000010' }} cursor={'pointer'} my={"36px"}>
                <IconButton
                  aria-label="Next image"
                  icon={<ArrowRightIcon />}
                  size="2xl"
                  color={{ base: 'white', md: 'black' }}
                  bgColor={'#00000000'}
                  _hover={{ backgroundColor: '#00000000' }}
                />
              </Flex>
            </Flex>

          </ModalBody>
        </ModalContent>
      </Modal>


    </Box>
  )
};

export { HowThisHomeWorks };

