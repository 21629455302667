import Cookies from "js-cookie";
import config from "../config";
import { getRebookReservationQuote, getReservationQuote } from "./fetch";

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;

export const updateReservation = (reservationNumber, reservationData) => {
  //console.log("Updating reservation", reservationNumber, reservationData);

  return fetch(`${API_ENDPOINT}/reservations/${reservationNumber}`, {
    method: "PUT",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reservationData),
  });
};

export const upsertDbReservation = (reservationNumber, reservationData) => {
  //console.log("Creating DB reservation", reservationNumber, reservationData);

  return fetch(
    `${API_ENDPOINT}/reservations/upsertDbReservation/${reservationNumber}`,
    {
      method: "POST",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservationData),
    }
  );
};

export const createInquiryReservation = async (inquiryData) => {
  //console.log("Creating inquiry reservation", inquiryData);

  return fetch(`${API_ENDPOINT}/reservations/createInquiry`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inquiryData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      console.error("Error creating inquiry reservation:", error);
      throw error;
    });
};

export const createReservedReservation = async (data) => {
  //console.log("Creating reserved reservation", data);

  return fetch(`${API_ENDPOINT}/reservations/createReserved`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((error) => {
      console.error("Error creating reserved reservation:", error);
      throw error;
    });
};


export async function fetchReservationQuote(
  queryParams: any,
  setNightlyRates: (value: any) => void,
  setReservationQuote: (value: any) => void,
  setErrorMessage: (value: string | null) => void,
  setDiscount: (value: string | null) => void
) {

  try {
    const response = await getReservationQuote(queryParams);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error.message || `HTTP error! Status: ${response.status}`
      );
    }

    const reservationQuote = await response.json();
    //console.log('Reservation Quote:', reservationQuote);

    const rates = reservationQuote.rates.ratePlans[0].ratePlan.money;
    setNightlyRates(rates.fareAccommodation);

    let calculatedTaxesAndFees = [];
    rates.invoiceItems.forEach((item) => {
      if (item.type !== "ACCOMMODATION_FARE") {
        calculatedTaxesAndFees.push(item);
      }
    });

    const discountItemAmount = rates.invoiceItems.find(
      (item) => item.type === "DISCOUNT"
    )?.amount;
    if (discountItemAmount) {
      setDiscount(discountItemAmount);
    }

    setReservationQuote(reservationQuote);
  } catch (error) {
    console.error("Error:", error.message);
    setErrorMessage(error.message);
  }
}

export const fetchBookingPaymentIntent = async (amount: number) => {
  return fetch(`${API_ENDPOINT}/stripe/createBookingPaymentIntent`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ amount }),
  });
};

export const createRebookReservation = async (data: any) => {
  return fetch(`${API_ENDPOINT}/reservations/rebook`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status === 202) {
        return response.json().then((json) => ({
          status: 202,
          partialSuccess: true,
          message:
            "Reservation reserved, manual handling needed. User will be contacted by support",
          reservationNumber: json.reservationNumber,
        }));
      }
      if (response.status === 200) {
        return response.json().then((json) => ({
          status: 200,
          message: "Reservation rebooked successfully",
          reservationNumber: json.reservationNumber,
        }));
      }
      if (!response.ok) {
        throw new Error("Please complete all fields and try again.");
      }
      return response.json();
    })
    .catch((error) => {
      console.error("Error creating rebook reservation:", error);
      throw error;
    });
};

export const getPaymentsBreakdown = async (data: any) => {
  return fetch(`${API_ENDPOINT}/reservations/getPaymentsBreakdown`, {
    method: "POST",
    headers: {
      ...defaultHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).catch((error) => {
    console.error("Error getting payments breakdown.", error);
    throw error;
  });
};

export const getCheckOutById = async (reservationNumber: string) => {
  return fetch(
    `${API_ENDPOINT}/reservations/${reservationNumber}/getCheckOutById`,
    {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error("Error getting reservation checkout.", error);
    throw error;
  });
};

export const verifyReservation = async (reservationNumber: string, checkinDate: string): Promise<boolean> => {

  const response = await fetch(`${API_ENDPOINT}/reservations/${reservationNumber}/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ checkinDate })
  });
  const data = await response.json();
  //console.log('verifyReservation', data, data.status);
  return data.status;
};

export const getRebookPricing = async (
  totalNightlyRates: number,
  houseNumber: string,
  reservationNumber: string,
  rebookDiscount: number
) => {

  // Build the URL with query parameters
  const url = new URL(`${API_ENDPOINT}/listings/calculateListingRates`);
  url.searchParams.append('totalNightlyRates', totalNightlyRates.toString());
  url.searchParams.append('houseNumber', houseNumber);
  url.searchParams.append('reservationNumber', reservationNumber);
  url.searchParams.append('rebookDiscount', rebookDiscount.toString());

  // Perform the GET request
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });

  // Parse the response
  if (!response.ok) {
    throw new Error(`Error fetching rebook pricing data: ${response.statusText}`);
  }

  const rebookPricingData = await response.json();
  //console.log('rebookPricingData', rebookPricingData);

  return rebookPricingData;
};

// Custom Reservation Quote That Returns Guesty Reservation Quote + Rebook Pricing Calculations
export async function fetchRebookReservationQuote(
  queryParams: any,
  setErrorMessage: (value: string | null) => void,
  setGuestyPricing: (value: any) => void,
  setRebookPricing: (value: any) => void,
  setOriginalGuestyQuote: (value: any) => void
) {

  try {
    const response = await getRebookReservationQuote(queryParams);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error.message || `HTTP error! Status: ${response.status}`
      );
    }

    const reservationQuote = await response.json();

    setGuestyPricing(reservationQuote.reservationQuote ?? reservationQuote.reservationQuote)
    setRebookPricing(reservationQuote.rebookPricingQuote ?? reservationQuote.rebookPricingQuote)
    setOriginalGuestyQuote(reservationQuote.originalGuestyQuote ?? reservationQuote.originalGuestyQuote)
  } catch (error) {
    console.error("Error:", error.message);
    setErrorMessage(error.message);
  }
}

// Custom Reservation Quote That Returns Receipts
export async function fetchReceipts(
  reservationNumber,
  setReceipts,
  setErrorMessage
) {
  try {
    const response = await fetch(`${API_ENDPOINT}/reservations/${reservationNumber}/receipts`, {
      method: 'GET',
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || `HTTP error! Status: ${response.status}`
      );
    }

    const data = await response.json();
    //console.log('receipt data', data)
    setReceipts(data.receipts);
  } catch (error) {
    console.error("Error fetching receipts:", error.message);
    setErrorMessage(error.message);
  }
}
