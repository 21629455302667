/* eslint-disable react-hooks/exhaustive-deps */
import { InfoIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  Link,
  Select,
  Text,
  Tooltip,
  VStack,
  useToast
} from "@chakra-ui/react";

import { useUser } from "@clerk/clerk-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { QuoteCard } from "../components/QuoteCard";
import { QuoteQuickSearchModal } from "../components/QuoteQuickSearchModal";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { reformatDateString } from "../helpers/helpers";
import {
  getQuote,
  getQuoteAdmin,
  saveQuoteNote,
  setActiveQuoteVariable,
  updateQuote
} from "../helpers/quoteService";

interface FormData {
  source: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Errors {
  source?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

const Quote = () => {
  let { quoteNumber } = useParams();

  const toast = useToast();

  const user = useUser();
  const userIsAdmin = useMemo(() => {
    return (
      user &&
      user.isSignedIn &&
      user.user.primaryEmailAddress &&
      user.user.primaryEmailAddress.emailAddress &&
      ["darrenhe.com", "cloudthoughts.com", "thisisdelmar.com"].some((domain) =>
        user.user.primaryEmailAddress.emailAddress.includes(domain)
      )
    );
  }, [user]);

  const { setActiveQuoteNumber } = useContext(SearchResultsContext);
  const [adminPreviewMode, setAdminPreviewMode] = useState(false);
  const [quoteItems, setQuoteItems] = useState([]);
  const [quote, setQuote] = useState(null);
  const [isEditing, setIsEditing] = useState(!quoteNumber);
  const [formData, setFormData] = useState<FormData>({
    source: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [rebookMode, setRebookMode] = useState(false);
  const [adminMode, setAdminMode] = useState(userIsAdmin);

  useEffect(() => {
    setAdminMode(userIsAdmin);
  }, [userIsAdmin]);

  useEffect(() => {
    const fetchData = async () => {
      let fetchedQuote;
      if (userIsAdmin) {
        fetchedQuote = await getQuoteAdmin(quoteNumber);
      } else {
        fetchedQuote = await getQuote(quoteNumber);
      }
      setQuote(fetchedQuote);
      setQuoteItems(fetchedQuote?.quoteItems || []);

      let source = fetchedQuote?.source?.toLowerCase() || '';
      if (source.includes("airbnb")) {
        source = "Airbnb";
      } else if (source.includes("vrbo") || source.includes("homeaway")) {
        source = "VRBO";
      } else if (source.includes("marriott")) {
        source = "Marriott";
      } else if (source.includes("booking")) {
        source = "Booking.com";
      } else if (["direct", "manual", "standard", "api", "be-api"].some(s => source.includes(s))) {
        source = "Direct";
      } else {
        source = '';
      }

      setFormData({
        source: source,
        firstName: fetchedQuote?.firstName || '',
        lastName: fetchedQuote?.lastName || '',
        email: fetchedQuote?.email || '',
      });
    };
    fetchData();
    setActiveQuoteVariable(quoteNumber);
    setActiveQuoteNumber(quoteNumber);
  }, [quoteNumber, userIsAdmin]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const validateForm = useCallback(() => {
    let formErrors: Errors = {};
    if (!formData.source) formErrors.source = "Source is required";
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }, [formData]);

  const handleUpdateQuote = useCallback(async () => {
    if (!validateForm()) return;

    const updatedQuote = await updateQuote(quoteNumber, formData);
    setQuote(updatedQuote);
    setIsEditing(false);
  }, [formData, quoteNumber, validateForm]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleQuoteItemRemove = useCallback((quoteItemId) => {
    setQuoteItems((prevItems) => prevItems.filter((item) => item.id !== quoteItemId));
  }, []);

  useEffect(() => {
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    if (rebookParameter) {
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneWeekInMilliseconds) {
        setRebookMode(true);
      }
    }
  }, []);

  const handleNavigate = useCallback((path) => {
    window.open(path, "_blank");
  }, []);

  const ALLOWED_TAGS = useMemo(() => [
    "Pool",
    "Hot Tub",
    "Dog Friendly",
    "No Dogs",
    "Walk to Water",
    "Waterfront",
    "Water View",
    "Central AC",
    "Game Room",
    "Signature",
  ], []);

  const getFormattedLinks = useCallback((fetchedQuote, platform) => {
    const formattedLinks = fetchedQuote.quoteItems.map((quoteItem) => {
      const { house, houseNumber, town, checkinDate, checkoutDate, guests } = quoteItem;
      //console.log('fetchedQuote', fetchedQuote)
      let platformLink = '';

      if (platform.includes('airbnb') && house?.airbnbLink) {
        platformLink = `${house.airbnbLink}/?check_in=${checkinDate}&check_out=${checkoutDate}&guests=${guests || 2}`;
      } else if ((platform.includes('vrbo') || platform.includes('homeaway')) && house?.vrboLink) {
        platformLink = `${house.vrboLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&adults=${guests || 2}`;
      } else if (platform.includes('marriott') && house?.marriottLink) {
        platformLink = `${house.marriottLink}/?startDate=${checkinDate}&endDate=${checkoutDate}&number_of_guests=${guests || 2}`;
      } else if (platform.includes('booking') && house?.bookingComLink) {
        platformLink = `${house.bookingComLink}/?checkin=${checkinDate}&checkout=${checkoutDate}&group_adults=${guests || 2}`;
      }

      // Only format the string if platformLink is not empty
      if (platformLink !== '') {
        return `${houseNumber}${town ? ' - ' + town : ''}, ${reformatDateString(checkinDate)} - ${reformatDateString(checkoutDate)}: ${platformLink}`;
      }

      return ''; // Return empty if no valid link
    }).filter(link => link !== '').join('\n\n'); // Join links with line breaks

    return formattedLinks;
  }, []);


  const handleGetAllLinks = useCallback(() => {
    let links = getFormattedLinks(quote, formData.source.toLowerCase());
    navigator.clipboard.writeText(links);
  }, [formData.source, getFormattedLinks, quote]);

  const handleSaveQuoteNoteClick = useCallback(async () => {
    const saveNoteResponse = await saveQuoteNote(quoteNumber);
    //console.log('saveNoteResponse', saveNoteResponse)
    if (saveNoteResponse.statusCode) {
      toast({
        title: 'Error Saving Quote to HubSpot Deal Note',
        description: 'There was an error saving the quote details to HubSpot.',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: "top"
      });
    } else {
      toast({
        title: 'Quote Saved to HubSpot Deal Note',
        description: 'The current quote items has been saved as a HubSpot Deal Note.',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: "top"
      });
    }
  }, [quoteNumber, toast]);

  return (
    <Box p={3} textAlign="center" fontSize="lg" bg={'gray.100'} minH={{ base: 'calc(100vh - 80px)', md: 'calc(100vh - 236px)' }}>
      <Flex align="start" gap={3} flexDirection={{ base: 'column', md: 'row' }}>
        {userIsAdmin && !adminPreviewMode ? (
          <AdminView
            isEditing={isEditing}
            quote={quote}
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            handleUpdateQuote={handleUpdateQuote}
            handleEdit={handleEdit}
            adminPreviewMode={adminPreviewMode}
            setAdminPreviewMode={setAdminPreviewMode}
            handleGetAllLinks={handleGetAllLinks}
            handleNavigate={handleNavigate}
            handleSaveQuoteNoteClick={handleSaveQuoteNoteClick}
            reformatDateString={reformatDateString}
            ALLOWED_TAGS={ALLOWED_TAGS}
          />
        ) : (
          <GuestView
            quote={quote}
            userIsAdmin={userIsAdmin}
            setAdminPreviewMode={setAdminPreviewMode}
          />
        )}

        {quote && quote.quoteItems && quote.quoteItems.length > 0 ? (
          <Flex direction="column" w={{ base: "100%", md: "67%" }} gap={3}>
            {quoteItems.map((item) => (
              <Box
                key={item.id}
                mx="auto"
                bg="white"
                rounded={{ md: "xl" }}
                shadow={{ md: "base" }}
                p={{ base: "4", md: "6" }}
                w={"100%"}
              >
                <QuoteCard
                  houseNumber={item.houseNumber}
                  checkinDate={item.checkinDate}
                  checkoutDate={item.checkoutDate}
                  guests={item.guests || 2}
                  adminMode={adminMode && !adminPreviewMode}
                  rebookMode={rebookMode}
                  quoteId={quoteNumber}
                  quoteItemId={item.id}
                  note={item.note}
                  coupon={item.coupon}
                  onRemove={handleQuoteItemRemove}
                />
              </Box>
            ))}
          </Flex>
        ) : (
          quote && (
            <Box
              w={'67%'}
              mx="auto"
              bg="white"
              rounded={{ md: "xl" }}
              padding="10"
              shadow={{ md: "base" }}
              px={{ base: "6", md: "8" }}
            >
              No quote items.<br /><br />
              <Link href="/" target="_blank" style={{ textDecoration: "underline", color: "dmNavy" }}>
                Start a new search
              </Link> with dates and guest count to add an item to this quote.
            </Box>
          )
        )}
      </Flex>
    </Box>
  );
};

const AdminView = ({
  isEditing,
  quote,
  formData,
  errors,
  handleInputChange,
  handleUpdateQuote,
  handleEdit,
  handleSaveQuoteNoteClick,
  adminPreviewMode,
  setAdminPreviewMode,
  handleGetAllLinks,
  handleNavigate,
  reformatDateString,
  ALLOWED_TAGS
}) => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalParams, setModalParams] = useState({});

  const handleOpenModal = (initialParams) => {
    setModalParams(initialParams);
    setModalOpen(true);
  };

  const handleSearch = (params) => {
    const queryParams = new URLSearchParams(params).toString();
    window.location.href = `/?${queryParams}`;
  };

  return (
    <Flex align="start" gap={3} flexDirection={'column'} w={{ base: '100%', md: '33%' }}>
      <Flex
        mx="auto"
        w={'100%'}
        bg="white"
        rounded={{ md: 'xl' }}
        shadow={{ md: 'base' }}
        p={{ base: '4', md: '6' }}
      >
        {isEditing ? (
          <Flex flexDirection={'column'} gap={2} align="start" textAlign={'left'} w={'100%'}>
            <Flex gap={2} w={'100%'}>
              {quote && <Text><b>ADMIN: Editing Quote ID:</b> {quote.id}</Text>}
            </Flex>
            <Select
              name="source"
              value={formData.source}
              onChange={handleInputChange}
              placeholder="Select Source"
            >
              <option value="Direct">Direct</option>
              <option value="Airbnb">Airbnb</option>
              <option value="VRBO">VRBO</option>
              <option value="Marriott">Marriott</option>
              <option value="Booking.com">Booking.com</option>
              <option value="Other">Other</option>
            </Select>
            {errors.source && <Text color="red.500">{errors.source}</Text>}
            <Input
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Guest First Name"
            />
            {errors.firstName && <Text color="red.500">{errors.firstName}</Text>}
            <Input
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Guest Last Name"
            />
            {errors.lastName && <Text color="red.500">{errors.lastName}</Text>}
            <Input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Guest Email"
            />
            {errors.email && <Text color="red.500">{errors.email}</Text>}
            {quote && <Button alignSelf={'end'} colorScheme={'dmOrange'} onClick={handleUpdateQuote}>Save</Button>}
          </Flex>
        ) : (
          quote && (
            <Flex flexDirection={'column'} gap={1} align="start" textAlign={'left'} wordBreak={'break-all'} w={'100%'}>
              <Flex gap={2}>
                <Text><b>ADMIN: Viewing Quote:</b> {quote.id}</Text>
              </Flex>
              <Text><b>Source:</b> {quote.source}</Text>
              <Text><b>First Name:</b> {quote.firstName}</Text>
              <Text><b>Last Name:</b> {quote.lastName}</Text>
              <Text><b>Email:</b> {quote.email}</Text>
              <Divider color={'dmNavy.900'} my={2} />
              <Text fontSize={'smaller'}>Last Modified By: {quote.lastModifiedBy}</Text>
              <Text fontSize={'smaller'}>Created At: {new Date(quote.createdAt).toLocaleString()}</Text>
              <Text fontSize={'smaller'}>Updated At: {new Date(quote.updatedAt).toLocaleString()}</Text>
              <Divider color={'dmNavy.900'} my={2} />
              <Flex gap={2} w={'100%'} justifyContent={'start'} alignItems={'start'} flexWrap={'wrap'}>
                <Button onClick={handleEdit}>Edit Info</Button>
                {adminPreviewMode ? (
                  <Button onClick={() => setAdminPreviewMode(false)}>Exit Preview as Guest</Button>
                ) : (
                  <Button onClick={() => setAdminPreviewMode(true)}>Preview Options as Guest</Button>
                )}
                <Button colorScheme={'red'}
                  onClick={
                    () => {
                      navigator.clipboard.writeText(`${window.location.origin}/quotes/${quote.id}`);
                      handleSaveQuoteNoteClick();
                    }
                  }
                >
                  Copy Quote Page Link
                </Button>
                {/*<Button colorScheme={'orange'} onClick={handleSaveQuoteNoteClick}>
                  Save Quote Note to HubSpot
                </Button>*/}
                {formData.source && ['marriott', 'airbnb', 'vrbo', 'homeaway', 'booking.com'].some(source => formData.source.toLowerCase().includes(source)) && (
                  <Button colorScheme={'red'}
                    onClick={
                      () => {
                        handleGetAllLinks();
                        handleSaveQuoteNoteClick();
                      }
                    }
                  >
                    Copy All {formData.source} Individual Links
                  </Button>
                )}
              </Flex>
              <Text fontSize="sm" pt={3} wordBreak={'normal'}>Clicking either Copy button above will save the Quote details to the HubSpot Inquiry Deal as a note. Please only click once to avoid duplicate notes.</Text>
            </Flex>
          )
        )}
      </Flex>

      {quote && quote.reservations && quote.reservations.length > 0 && (
        <Flex
          mx="auto"
          w={'100%'}
          bg="white"
          rounded={{ md: 'xl' }}
          shadow={{ md: 'base' }}
          p={{ base: '4', md: '6' }}
        >
          <Flex flexDirection={'column'} gap={4} align="start" textAlign={'left'} w={'100%'}>
            <Flex gap={3} justifyContent={'center'} alignItems={'start'} flexDirection={'column'} w="100%">
              <HStack spacing={2}>
                <Text fontWeight="bold" fontSize="lg">ADMIN: Recent Reservations</Text>
                <Tooltip
                  _hover={{ cursor: 'pointer' }}
                  label="Recent reservations matching on email only."
                  aria-label="Recent Reservation Tooltip"
                >
                  <InfoIcon width={'15px'} color={'#666'} />
                </Tooltip>
              </HStack>
              <VStack spacing={4} w={'100%'} align="start" fontSize={'16px'}>
                {quote.reservations.map((reservation) => (
                  <Box
                    key={reservation.reservationNumber}
                    w="100%"
                    p={3}
                    bg="gray.100"
                    rounded="md"
                    shadow="sm"
                  >
                    <Flex gap={2} mb={2} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
                      <Text fontWeight='bold' fontSize={'18px'}>{reservation.reservationNumber}</Text>
                      <Flex gap={1}>
                        <Button size='xs' variant='outline' colorScheme={'blue'} leftIcon={<ViewIcon />}
                          onClick={() => window.open(`https://app.guesty.com/reservations/${reservation.guestyId}/summary`, '_blank')}
                        >
                          Guesty
                        </Button>
                        <Button size='xs' variant='outline' colorScheme={'orange'} leftIcon={<ViewIcon />}
                          onClick={() => window.open(`https://app.hubspot.com/contacts/21053466/record/2-6409940/${reservation.hubspotReservationId}`, '_blank')}
                        >
                          HubSpot
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex my={1} gap={1} w={'100%'} flexWrap={'wrap'}>
                      {reservation.typename && <Badge colorScheme={'orange'}>{reservation.typename}</Badge>}
                      {reservation.rating && reservation.rating > 0 ?
                        <Badge colorScheme={reservation.rating > 3 ? 'green' : 'red'} className='prev-reservation-rating'>
                          {reservation.rating}-star
                        </Badge>
                        :
                        <Badge colorScheme={'yellow'} className='prev-reservation-rating'>
                          No rating
                        </Badge>
                      }
                      {reservation.dogAgreementSigned && <Badge colorScheme={'orange'}>Dog Agreement</Badge>}
                    </Flex>
                    <Text><b>House:</b> {reservation.houseNumber}</Text>
                    <Text><b>Guests:</b> {reservation.adults}</Text>
                    <Text><b>Dates:</b> {reformatDateString(reservation.checkinDate)} - {reformatDateString(reservation.checkoutDate)}</Text>
                    {reservation.totalPrice && <Text><b>Total Price:</b> ${(reservation.totalPrice).toFixed(2)}</Text>}

                    <Text mt={2}><b>Quick Search:</b></Text>
                    <Flex mt={1} gap={1} w={'100%'} flexWrap={'wrap'}>
                      <Badge
                        onClick={() => handleNavigate(`/${reservation.houseNumber}`)}
                        colorScheme={'blue'}
                        _hover={{ cursor: 'pointer' }}
                      >
                        {reservation.houseNumber}
                      </Badge>
                      {reservation.house && reservation.house.town &&
                        <Badge
                          onClick={() => handleOpenModal({ town: reservation.house?.town, startdate: quote.quoteItems[0]?.checkinDate, enddate: quote.quoteItems[0]?.checkoutDate, guests: quote.quoteItems[0]?.guests })}
                          colorScheme={'blue'}
                          _hover={{ cursor: 'pointer' }}
                        >
                          {reservation.house.town}
                        </Badge>
                      }
                      {reservation.house && reservation.house.tags &&
                        reservation.house.tags.split(';')
                          .map(tag => tag.trim())
                          .filter(tag => ALLOWED_TAGS.includes(tag))
                          .map(tag => (
                            <Badge
                              onClick={() => handleOpenModal({ tags: tag, town: reservation.house?.town, startdate: quote.quoteItems[0]?.checkinDate, enddate: quote.quoteItems[0]?.checkoutDate, guests: quote.quoteItems[0]?.guests })}
                              key={tag}
                              colorScheme={'blue'}
                              _hover={{ cursor: 'pointer' }}
                            >
                              {tag}
                            </Badge>
                          ))
                      }
                    </Flex>

                  </Box>
                ))}
                <QuoteQuickSearchModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                  onSearch={handleSearch}
                  initialParams={modalParams}
                  ALLOWED_TAGS={ALLOWED_TAGS}
                />
              </VStack>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const GuestView = ({ quote, userIsAdmin, setAdminPreviewMode }) => {
  return (
    <Flex align="start" gap={3} flexDirection={'column'} w={{ base: '100%', md: '33%' }}>
      <Flex
        mx="auto"
        w={'100%'}
        bg="white"
        rounded={{ md: 'xl' }}
        shadow={{ md: 'base' }}
        p={{ base: '4', md: '6' }}
      >
        {quote && (
          <Flex flexDirection={'column'} gap={1} align="start" textAlign={'left'}>
            <Flex gap={2}>
              <Text><b>Viewing Quote:</b> {quote.id}</Text>
            </Flex>
            <Text><b>Source:</b> {quote.source}</Text>
            <Text><b>First Name:</b> {quote.firstName}</Text>
            <Text><b>Last Name:</b> {quote.lastName}</Text>
            <Text><b>Email:</b> {quote.email}</Text>
          </Flex>
        )}
      </Flex>
      {userIsAdmin && (
        <Button alignSelf={'start'} colorScheme={'dmOrange'} onClick={() => setAdminPreviewMode(false)}>Back to Admin View</Button>
      )}
    </Flex>
  );
};

export { Quote };

