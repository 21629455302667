import Cookies from "js-cookie";
import config from "../config";
import { isNil, isValidDate } from "./helpers";

export const checkDateRange = (start, end) => {
  if (isNil(start) || isNil(end)) {
    return false;
  }

  if (!isValidDate(start) || !isValidDate(end)) {
    return false;
  }

  const checkInDate = new Date(start);
  const checkOutDate = new Date(end);

  if (checkInDate > checkOutDate) {
    return false;
  }

  if (checkInDate < new Date()) {
    return false;
  }
  return true;
};
export const checkQuoteParams = (queryParams) => {
  if (isNil(queryParams)) {
    return false;
  }
  const { checkInDateLocalized, checkOutDateLocalized, listingId } =
    queryParams;

  if (
    isNil(checkInDateLocalized) ||
    isNil(checkOutDateLocalized) ||
    isNil(listingId)
  ) {
    return false;
  }

  if (
    !isValidDate(checkInDateLocalized) ||
    !isValidDate(checkOutDateLocalized)
  ) {
    return false;
  }

  // finally check if the checkInDate is before the checkOutDate and if some of them are in the past

  const checkInDate = new Date(checkInDateLocalized);
  const checkOutDate = new Date(checkOutDateLocalized);

  if (checkInDate > checkOutDate) {
    return false;
  }

  if (checkInDate < new Date()) {
    return false;
  }

  return true;
};

export const setActiveQuoteVariable = (quoteId: string) => {
  try {
    localStorage.setItem("dm-active-quote-number", quoteId);
  } catch (error) {
    console.error("Error setting active quote variable", error);
  }
};

export const getActiveQuoteVariable = () => {
  try {
    const localQuoteNumber = localStorage.getItem("dm-active-quote-number");
    return localQuoteNumber;
  } catch (error) {
    console.error("Error setting active quote variable", error);
    return;
  }
};

const defaultHeaders = () => {
  const token = Cookies.get("__session");
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
};

const API_ENDPOINT = config.apiEndpoint;

export const getAllQuotes = async () => {
  //console.log("Retrieving all quotes");
  try {
    const response = await fetch(`${API_ENDPOINT}/quotes/admin/list`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving all quotes from backend", error);
  }
};

export const getQuote = async (quoteId: string) => {
  //console.log("Retrieving quote:", quoteId);
  try {
    const response = await fetch(`${API_ENDPOINT}/quotes/${quoteId}`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving quote:", quoteId, error);
  }
};

export const getQuoteAdmin = async (quoteId: string) => {
  //console.log("Retrieving quote:", quoteId);
  try {
    const response = await fetch(`${API_ENDPOINT}/quotes/admin/${quoteId}`, {
      method: "GET",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error retrieving quote:", quoteId, error);
  }
};

export const createQuote = async (quoteData: any) => {
  //console.log("Creating new quote");
  try {
    const response = await fetch(`${API_ENDPOINT}/quotes/admin/createQuote`, {
      method: "POST",
      headers: {
        ...defaultHeaders(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quoteData),
    });
    return await response.json();
  } catch (error) {
    console.error("Error creating new quote", error);
  }
};

export const updateQuote = async (quoteId: string, quoteData: any) => {
  //console.log("Update quote:", quoteId);
  try {
    const response = await fetch(
      `${API_ENDPOINT}/quotes/admin/updateQuote/${quoteId}`,
      {
        method: "PUT",
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quoteData),
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error update quote:", quoteId, error);
  }
};

export const addQuoteItem = async (quoteId: string, quoteItemData: any) => {
  //console.log("Adding to quote:", quoteId);
  try {
    const response = await fetch(
      `${API_ENDPOINT}/quotes/admin/${quoteId}/createItem`,
      {
        method: "POST",
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quoteItemData),
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error adding to quote:", quoteId, error);
  }
};

export const deleteQuoteItem = async (quoteId: string, quoteItemId: string) => {
  //console.log("Deleting from quote:", quoteId, "quoteItemId:", quoteItemId);
  try {
    const response = await fetch(
      `${API_ENDPOINT}/quotes/admin/${quoteId}/removeItem/${quoteItemId}`,
      {
        method: "POST",
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (error) {
    console.error(
      "Error deleting from quote:",
      quoteId,
      "quoteItemId:",
      quoteItemId,
      error
    );
  }
};

export const updateQuoteItem = async (
  quoteId: string,
  quoteItemId: string,
  quoteItemData: any
) => {
  //console.log("Update quote:", quoteId, "quoteItemId:", quoteItemId);
  try {
    const response = await fetch(
      `${API_ENDPOINT}/quotes/admin/${quoteId}/updateItem/${quoteItemId}`,
      {
        method: "PUT",
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quoteItemData),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(
      "Error deleting from quote:",
      quoteId,
      "quoteItemId:",
      quoteItemId,
      error
    );
  }
};

export const saveQuoteNote = async (
  quoteId: string
) => {
  //console.log("Saving quote note to HubSpot for:", quoteId);
  try {
    const response = await fetch(
      `${API_ENDPOINT}/quotes/admin/${quoteId}/addActivityNoteToDeal`,
      {
        method: "POST",
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (error) {
    console.error(
      "Error saving quote note to HubSpot for:",
      quoteId,
      error
    );
  }
}