import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Button, Flex, Heading,
  Image,
  Link, ListItem, Skeleton, Text, UnorderedList, useDisclosure
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import * as React from "react";
import { BsCalendar } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TripMap from "../components/TripMap";
import { MobileMenu } from "../components/guestportal/MobileMenu";
import { PastReservationWidget } from "../components/guestportal/PastReservationWidget";
import { ReservationLookup } from "../components/guestportal/ReservationLookup";
import { ReservationWidget } from "../components/guestportal/ReservationWidget";
import { TripDetails } from "../components/guestportal/TripDetails";
import TripDetailsSkeleton from "../components/guestportal/TripDetailsSkeleton";
import { WelcomePopup } from "../components/guestportal/WelcomePopup";
import { useTrips } from "../contexts/TripContext";
import { omitNullAndUndefined } from "../helpers/helpers";
import { sendHubspotEvent } from "../helpers/hubspot";
import { getCheckOutById, getRebookPricing } from "../helpers/reservationService";

const Trips = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paramReservationNumber } = useParams();

  const { user } = useUser();
  const userFirstName = user.firstName;
  const userEmail = user.primaryEmailAddress.emailAddress;
  const userFirstSignIn = user.unsafeMetadata?.loggedIn ? false : true;
  const { unsafeMetadata = {} } = user;

  if (userFirstSignIn) {
    user.update({
      unsafeMetadata: { ...unsafeMetadata, loggedIn: true },
    });
  }

  const { allTripsData, updateAllTrips, selectedTrip, setSelectedTrip, pastTrips, currentFutureTrips, currentFutureCount, isTripsContextLoaded } = useTrips();
  const [reservationSearchSuccessful, setReservationSearchSuccessful] = React.useState(false);
  const [validTripParam, setValidTripParam] = React.useState(false);

  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure(); // Reservation Lookup Modal

  React.useEffect(() => {
    updateAllTrips();
  }, [user, reservationSearchSuccessful, paramReservationNumber]);

  React.useEffect(() => {
    if (currentFutureTrips && currentFutureTrips.length > 0) {
      setSelectedTrip(selectedTrip || currentFutureTrips[0] || allTripsData[0]);
    }
  }, [currentFutureTrips]);

  //console.log("selected trip", selectedTrip)

  // Handler for No Upcoming Reservations Rebook Button Opening Past Reservations Accordion
  const accordionRef = React.useRef(null);
  const { isOpen: isAccordionOpen, onToggle: toggleAccordion, onClose: closeAccordion } = useDisclosure({ defaultIsOpen: true });

  const handleButtonClick = () => {
    toggleAccordion();
    setTimeout(() => {
      accordionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100); // Adjust the delay as needed
  };

  let counter = 0;

  React.useEffect(() => {
    if (paramReservationNumber && isTripsContextLoaded) {
      // Check if the current selectedTrip is not the one specified by paramReservationNumber
      if (selectedTrip?.reservationNumber !== paramReservationNumber) {
        // Find the matching trip from allTripsData using the paramReservationNumber
        const matchingTrip = allTripsData.find(trip => trip.reservationNumber === paramReservationNumber);
        // If a matching trip is found, update selectedTrip with it
        if (matchingTrip) {
          setSelectedTrip(matchingTrip);
          setValidTripParam(true);
        } else {
          setValidTripParam(false);
        }
      } else if (selectedTrip?.reservationNumber === paramReservationNumber) {
        setValidTripParam(true);
      }
    }
  }, [paramReservationNumber, isTripsContextLoaded]);


  // HubSpot Custom Event // 
  const eventFiredRef = React.useRef(false); // Track if event has been fired
  const eventName = "pe21053466_my_trips_page_view";
  const properties = omitNullAndUndefined({
    hs_page_url: window.location.href,
  })
  const eventData = {
    eventName,
    properties,
    ...(userEmail ? { email: userEmail } : {}), // Only add email if it’s valid
  };

  React.useEffect(() => {
    if (!eventFiredRef.current && Object.keys(properties).length > 0) {
      // Fire the event only once
      eventFiredRef.current = true;
      (async () => {
        try {
          //console.log('Sending eventData:', eventData);
          await sendHubspotEvent(eventData);
        } catch (error) {
          console.error('Error sending HubSpot event:', error);
        }
      })();
    }
  }, [properties]); // Only re-run if `properties` change
  // END HubSpot Custom Event //


  /// Rebook Mode Variables ///
  const [rebookMode, setRebookMode] = React.useState(false);
  const [rebookParameterReservation, setRebookParameterReservation] = React.useState(null);
  const [rebookCoupon, setRebookCoupon] = React.useState(null);
  const [rebookCouponReady, setRebookCouponReady] = React.useState(false);
  const [rebookDiscountPercentage, setRebookDiscountPercentage] = React.useState(null);

  React.useEffect(() => {
    // Moving this here from ReservationContext to make sure it fires in time
    const setAttributionFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const attribution = urlParams.get("source");
      const rebookMode = urlParams.get("rebook");
      if (attribution) {
        localStorage.setItem("dm-attribution", attribution);
      }
      if (rebookMode) {
        localStorage.setItem("dm-rebook-mode", Date.now().toString());
        localStorage.setItem("dm-rebook-reservation", rebookMode);
      }
    };

    setAttributionFromUrl();

    // Check localStorage to see if rebook mode is active
    const rebookParameter = localStorage.getItem("dm-rebook-mode");
    const rebookValue = localStorage.getItem("dm-rebook-reservation"); // local version of variable for rebookParameterReservation
    setRebookParameterReservation(
      localStorage.getItem("dm-rebook-reservation")
    );
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    //console.log('rebookParameter', rebookParameter, 'rebookParameterReservation', localStorage.getItem('dm-rebook-reservation'), 'rebookValue', rebookValue)

    let isRebook; // local version of variable for rebookMode

    // If rebookParameter is set, check if it's been less than 24 hours since the last rebook
    if (rebookParameter) {
      //console.log('rebookParameter Time check')
      const rebookTimestamp = parseInt(rebookParameter, 10);
      if (currentTime - rebookTimestamp < oneWeekInMilliseconds) {
        //console.log('rebookMode set')
        isRebook = true;
        setRebookMode(true);
      } else {
        //console.log('rebookMode not set')
        isRebook = false;
        setRebookMode(false);
      }
    }

    //console.log('isRebook', isRebook, 'rebookMode', rebookMode, 'rebookParameterReservation', rebookParameterReservation,'rebookValue', rebookValue )

    // Function to get the checkout date and coupon code for rebook mode
    const getRebookCheckoutDateAndCoupon = async () => {
      //console.log('rebookParameterReservation', rebookValue)
      if (rebookValue === "stayfi") {
        //console.log("stayfi branch")
        setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
        setRebookDiscountPercentage(0.12);
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "4be10cd6da4b41ee20492eab7bec109d") {
        setRebookCoupon("1KRYHEMOUFVN"); // 5%
        setRebookDiscountPercentage(0.05);
        setRebookCouponReady(true);
        return;
      }
      if (rebookValue === "2f206aa4d52f82d7ee10d01300aa59b7") {
        setRebookCoupon("DXDJJQDVJJID"); // 10%
        setRebookDiscountPercentage(0.1);
        setRebookCouponReady(true);
        return;
      }
      const response = await getCheckOutById(rebookValue);
      const data = await response.json();
      //console.log('rebook data', data);

      if (data && data.checkoutDate) {
        const rebookCheckOutDate = data.checkoutDate; // Date String
        const timeDifference =
          new Date().getTime() - new Date(rebookCheckOutDate).getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        if (daysDifference < 1) {
          setRebookCoupon("FJCP97YWVP9J"); // 12% (stayfi)
          setRebookDiscountPercentage(0.12);
          setRebookCouponReady(true);
        } else if (daysDifference <= 14) {
          setRebookCoupon("DXDJJQDVJJID"); // 10%
          setRebookDiscountPercentage(0.1);
          setRebookCouponReady(true);
        } else {
          setRebookCoupon("1KRYHEMOUFVN"); // 5%
          setRebookDiscountPercentage(0.05);
          setRebookCouponReady(true);
        }
      }
      setRebookCouponReady(true);
    };

    // If rebookParameter and rebookMode are set, get the checkout date and coupon code
    if (rebookValue && isRebook) {
      getRebookCheckoutDateAndCoupon();
    }
    //console.log('rebookCoupon', rebookCoupon, 'rebookCouponReady', rebookCouponReady)
    setRebookCouponReady(true);
  }, [location]);

  /// END Rebook Mode Variables ///


  // Get Rebook Pricing //
  const [rebookPricingData, setRebookPricingData] = React.useState(null);
  const [fetchingRebookPricing, setFetchingRebookPricing] = React.useState(true);

  React.useEffect(() => {
    setFetchingRebookPricing(true);
    const fetchRebookPricing = async () => {
      if (selectedTrip && rebookDiscountPercentage) {
        try {
          const rebookPricing = await getRebookPricing(2531, selectedTrip.houseNumber, selectedTrip.reservationNumber, rebookDiscountPercentage);
          //console.log("rebookPricing", rebookPricing);
          setRebookPricingData(rebookPricing);
        } finally {
          setFetchingRebookPricing(false);
        }
      }
    };
    fetchRebookPricing();
  }, [selectedTrip, rebookDiscountPercentage]);

  // END Get Rebook Pricing


  // Rebook Button Click Handler //

  const handleRebookButtonClick = (rebookType) => {
    const eventName = "pe21053466_rebook_button_click";
    const properties = {
      house_number: selectedTrip.houseNumber,
      reservation_number: selectedTrip.reservationNumber,
      rebook_click_type: rebookType,
      hs_page_url: window.location.href,
    }

    if (userEmail) {
      // Fire and forget
      (async () => {
        try {
          await sendHubspotEvent({ eventName, properties, email: userEmail });
        } catch (error) {
          console.error("Error sending HubSpot event:", error);
        }
      })();
    }

    localStorage.setItem('dm-rebook-reservation', selectedTrip.reservationNumber);

    if (rebookType === "thishome") {
      window.open(`/${selectedTrip.houseNumber}/?source=guestportal&rebook=${selectedTrip.reservationNumber}`, '_blank');
    } else {
      window.open(`/?source=guestportal&rebook=${selectedTrip.reservationNumber}`, '_blank');
    }
  }

  // END Rebook Button Click Handler //


  return (
    <>
      <Flex className={'tripcontainer'} w={'100vw'}>
        {validTripParam && paramReservationNumber && selectedTrip ?
          <Flex
            pt={{ base: 2, lg: 8 }}
            justifyContent={"start"}
            alignItems={"center"}
            direction={"column"}
            w={'100vw'}
            maxW={'720px'}
            className={'trip-details-container'}
          >
            <Flex
              direction={{ base: "column" }}
              textAlign={"left"}
              justifyContent={"center"}
              maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
              pb={3}
            >
              <Box alignItems="center" fontSize={{ base: 16, md: 20 }} mb={{ base: 2, md: 4 }}>
                <Flex my={3}>
                  <ChevronLeftIcon onClick={() => navigate('/trips')} />
                  <Text as="span" cursor="pointer" ml={2} lineHeight={'16px'} onClick={() => navigate('/trips')} >
                    Back to My Trips
                  </Text>
                </Flex>
              </Box>
              <TripDetails
                userEmail={userEmail}
              />
            </Flex>
          </Flex>
          : validTripParam && paramReservationNumber && !selectedTrip ?
            <TripDetailsSkeleton />
            :
            <Flex
              pt={{ base: 2, lg: 16 }}
              justifyContent={"start"}
              alignItems={"center"}
              direction={"column"}
              w={{ base: '100vw', md: '50vw' }}
              maxW={'720px'}
              className={'reservationcontainer'}
            >
              <Flex
                direction={{ base: "column" }}
                textAlign={"left"}
                justifyContent={"center"}
                maxW={"680px"} mx={'20px'} w={'calc(100% - 40px)'}
                pb={3}
              >
                {userFirstName &&
                  <Heading
                    pb={{ base: 0, md: 5 }}
                    pt={{ base: 4, sm: 6, lg: 0 }}
                    pl={{ base: 0, xl: 0 }}
                    fontWeight={"normal"}
                  >
                    Welcome, {userFirstName}
                  </Heading>
                }

                <Box display={{ base: "none", md: "block" }} fontSize={{ base: 20, md: 24 }} fontWeight={'bold'} mb={{ base: 2, md: 4 }}>
                  <h1>My Trips</h1>
                </Box>

                {!isTripsContextLoaded ? (
                  <>
                    <Flex direction={{ base: "column", md: "row" }} mb={"200px"}>
                      <Skeleton
                        height="380px"
                        w={{ base: '90vw', md: '50vw' }}
                        mt={3}
                        maxW={'680px'}
                        borderLeftRadius={{ base: 0, md: 6 }}
                        borderTopRadius={{ base: 6, md: 0 }}
                        mb={{ base: 0, md: 4 }}
                      />
                    </Flex>
                  </>
                ) : (currentFutureCount === 0 || !currentFutureCount) ? (
                  <Flex
                    direction={"column"}
                    textAlign={"left"}
                    fontSize={"lg"}
                    width={"100%"}
                    pl={{ base: 0, xl: 2 }}
                    pt={4} pb={16}
                  >
                    <Flex fontSize={20} mb={4}>
                      You have no upcoming reservations, but we would love to see you again!
                    </Flex>
                    <Flex
                      flexDirection={{ base: "column", md: "row" }} gap={3}
                    >
                      {allTripsData.length > 0 &&
                        <Button
                          rightIcon={<BsCalendar />}
                          className="dm-button-orange-pill explore-homes-past-reservation-widget"
                          w={{ base: "100%", md: "300px" }}
                          boxShadow="base"
                          onClick={handleButtonClick}
                        >
                          Rebook a home
                        </Button>
                      }
                      <Button
                        rightIcon={<IoIosSearch />}
                        className={allTripsData.length > 0 ? "dm-button-gray-pill explore-homes-past-reservation-widget" : "dm-button-orange-pill explore-homes-past-reservation-widget"}
                        w={{ base: "100%", md: "300px" }}
                        boxShadow="base"
                        onClick={() => window.open("/", "_blank")}
                      >
                        Explore all homes
                      </Button>
                    </Flex>
                    <Flex pt={16} pb={5} fontWeight={"bold"}>
                      <i>Expecting to see an active or upcoming reservation?</i>
                    </Flex>

                    <Flex>
                      Make sure you clicked the sign-in link in the welcome email,
                      and are logged in with the same user account. Alternatively, you
                      can search for your reservation below.
                    </Flex>
                    <Link mb={5} onClick={onSearchOpen} w={{ base: "100%", md: "300px" }}>
                      <Button
                        className="dm-button-navy-outline find-reservation"
                        w={{ base: "100%", md: "300px" }}
                        boxShadow="base"
                        mt={8}
                      >
                        Find my reservation
                      </Button>
                    </Link>

                  </Flex>
                ) : (
                  <i />
                )}
              </Flex>

              {allTripsData && currentFutureCount > 0 &&
                currentFutureTrips
                  .sort((a, b) => {
                    return (
                      new Date(a.checkinDate).getTime() -
                      new Date(b.checkinDate).getTime()
                    );
                  })
                  .map((reservation) => {
                    if (
                      reservation.house &&
                      (reservation.status === "Booked" || reservation.status === "Modified" || reservation.status === "Closed")
                    ) {
                      return (
                        <ReservationWidget
                          key={reservation["reservationNumber"]}
                          reservationData={reservation}
                          reservationIndex={counter++}
                        />
                      );
                    } else {
                      return <i key={reservation["reservationNumber"]} />;
                    }
                  })}

              <Flex
                direction={{ base: "column" }}
                justifyContent={"center"}
                maxW={"688px"} mx={'16px'} w={'calc(100% - 32px)'}
                fontWeight={'500'} pb={12} fontStyle={'italic'}
                textAlign={'center'}
              >
                Newly booked reservations may take 10-15 minutes before they are displayed.
              </Flex>

              {allTripsData && pastTrips &&
                (() => {
                  pastTrips
                    .sort((a, b) => {
                      return (
                        new Date(b.checkinDate).getTime() -
                        new Date(a.checkinDate).getTime()
                      );
                    });

                  if (pastTrips.length > 0) {
                    return (
                      <>
                        <Box
                          ref={accordionRef}
                          width={'100%'}
                          maxW={"720px"}
                          textAlign={"left"}
                          pb={24}
                        >
                          <Accordion
                            allowToggle
                            index={isAccordionOpen ? [0] : []}
                            onChange={toggleAccordion}
                            maxW={"680px"} mx={'20px'}
                            bgColor={'#EFEFEF'}
                          >
                            <AccordionItem>
                              <AccordionButton fontSize={24} fontWeight={'bold'}>
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  fontSize={24}
                                  fontWeight={"bold"}
                                  my={2}
                                  onClick={toggleAccordion}
                                >
                                  Past Reservations
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel
                                px={{ base: 0 }}
                              >
                                {pastTrips.map((reservation) => (
                                  <PastReservationWidget
                                    key={reservation["reservationNumber"]}
                                    reservationData={reservation}
                                  />
                                ))}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </Box>

                      </>
                    );
                  }
                })()
              }
            </Flex>
        }
        <Flex className="tripmapcontainer" h={{ base: 'calc(100vh - 150px)', md: 'calc(100vh - 90px)' }}
          w={{ base: '100%' }}
          position={'sticky'} right='0px' top='80px'
          display={{ base: 'none', md: 'flex' }}
          background={`url(${('../media/rebookbackground1.png')}) center/contain no-repeat`}
          backgroundPosition={'bottom'}
          backgroundColor={'#FBFBFB'}
        >
          {validTripParam && paramReservationNumber && selectedTrip && !fetchingRebookPricing ?
            <Flex direction={'column'} w={'100%'} alignItems={'center'} mt={20}>
              <Text fontSize="4xl" textDecoration={'underline'} textDecorationColor={'dmOrange.500'} textDecorationThickness={'1px'}>
                Rebook {selectedTrip.channel === 'Standard' ? 'here' : 'direct'} and save!
              </Text>
              <Text fontSize="lg" mt={2} color={'dmOrange.500'}>
                <i>An exclusive guest portal discount</i>
              </Text>

              {['standard', 'direct', 'website', 'manual', 'be-api'].includes(selectedTrip.channel.toLowerCase()) ?
                <>
                  <Text mt={8} fontSize={'xl'}>
                    Rebook similar dates on our website, and you'll pay:
                  </Text>
                  <Flex h={'120px'} w={{ base: '100%' }} p={4} mt={2} gap={3} maxW={'400px'}
                    border="solid 1px" borderColor={'dmNavy.500'} borderRadius={'12px'} overflow={'hidden'}
                  >
                    <Image src={selectedTrip.house.images[0].link} w={'50%'} objectFit='cover'></Image>
                    <Flex w={'50%'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                      <Text fontSize={'3xl'} color={'dmOrange.500'} fontWeight={'bold'}>
                        {rebookPricingData.channelSubTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text fontSize={'sm'} color={'dmOrange.500'}>Total nightly rates*</Text>
                    </Flex>
                  </Flex>
                  <Text mt={8} fontSize={'xl'}>
                    Rebook here in the guest portal and pay only:
                  </Text>
                  <Flex h={'120px'} w={{ base: '100%' }} p={4} mt={2} gap={3} maxW={'400px'}
                    border="solid 1px" borderColor={'dmNavy.500'} borderRadius={'12px'} overflow={'hidden'}
                  >
                    <Image src={selectedTrip.house.images[0].link} w={'50%'} objectFit='cover'></Image>
                    <Flex w={'50%'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                      <Text fontSize={'3xl'} fontWeight={'bold'}>
                        {rebookPricingData.rebookSubTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text fontSize={'sm'} color={'dmNavy.500'}>Total nightly rates*</Text>
                    </Flex>
                  </Flex>
                </>
                :
                <>
                  <Text mt={8} fontSize={'xl'}>
                    Rebook on {selectedTrip.channel} for similar dates in {(new Date().getFullYear()) + 1}, and you'll pay:
                  </Text>
                  <Flex h={'120px'} w={{ base: '100%' }} p={4} mt={2} gap={3} maxW={'500px'}
                    border="solid 1px" borderColor={'dmNavy.500'} borderRadius={'12px'} overflow={'hidden'}
                  >
                    <Image src={selectedTrip.house.images[0].link} w={'30%'} objectFit='cover'></Image>
                    <Image src={`../media/${selectedTrip.channel.toLowerCase()}.png`} w={'30%'} objectFit='contain' px={2}></Image>
                    <Flex w={'30%'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                      <Text fontSize={'3xl'} color={'dmOrange.500'} fontWeight={'bold'}>
                        {rebookPricingData.channelSubTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text fontSize={'sm'} color={'dmOrange.500'}>Total nightly rates*</Text>
                    </Flex>
                  </Flex>
                  <Text mt={8} fontSize={'xl'}>
                    Rebook the same dates direct with Del Mar and pay only:
                  </Text>
                  <Flex h={'120px'} w={{ base: '100%' }} p={4} mt={2} gap={3} maxW={'500px'}
                    border="solid 1px" borderColor={'dmNavy.500'} borderRadius={'12px'} overflow={'hidden'}
                  >
                    <Image src={selectedTrip.house.images[0].link} w={'30%'} objectFit='cover'></Image>
                    <Image src={`../media/delmar.png`} w={'30%'} objectFit='contain' px={2}></Image>
                    <Flex w={'30%'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                      <Text fontSize={'3xl'} fontWeight={'bold'}>
                        {rebookPricingData.rebookSubTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                      <Text fontSize={'sm'} color={'dmNavy.500'}>Total nightly rates*</Text>
                    </Flex>
                  </Flex>
                </>
              }
              <Text mt={6} fontSize={'md'}>
                * Rates shown do not include state taxes and other fees.
              </Text>
              <Flex flexDirection='column' gap={2} w={{ base: '100%' }} maxW={'550px'} justifyContent={'left'} mt={8}>
                <Text fontSize="4xl" textDecoration={'underline'} textDecorationColor={'dmOrange.500'} textDecorationThickness={'1px'}>
                  Plus:
                </Text>
                <UnorderedList pl={4} fontSize={'xl'}>
                  <ListItem>Reserve any home with only a $300 deposit.</ListItem>
                  <ListItem>Reserve the best dates, at the best home.</ListItem>
                  <ListItem>Flexible cancellation.</ListItem>
                  <ListItem>Change dates or home at any time.</ListItem>
                </UnorderedList>
                <Flex gap={4} justifyContent={'center'} alignContent={'center'} mt={6}>
                  <Button
                    onClick={() => { handleRebookButtonClick('thishome') }}
                    className="dm-button-orange-pill rebook-redirect-booking-site"
                    w={{ base: "100%", lg: "100%" }}
                    boxShadow="base"
                  >
                    Rebook this Home
                  </Button>
                  <Button
                    onClick={() => { handleRebookButtonClick('otherhome') }}
                    className="dm-button-navy-outline"
                    w={{ base: "100%", lg: "100%" }}
                    boxShadow="base"
                  >
                    Explore other homes
                  </Button>
                </Flex>
              </Flex>

            </Flex>
            :
            <TripMap navigate={navigate} reservations={currentFutureTrips} />
          }
        </Flex>
      </Flex>

      {isTripsContextLoaded &&
        <WelcomePopup modalInitiateOpen={userFirstSignIn} />
      }

      {isTripsContextLoaded &&
        <ReservationLookup
          modalInitiateOpen={isSearchOpen}
          reservationData={allTripsData}
          onModalClose={onSearchClose}
          setReservationSearchSuccessful={setReservationSearchSuccessful}
          reservationSearchSuccessful={reservationSearchSuccessful}
        />
      }

      {isTripsContextLoaded && allTripsData.length > 0 && (
        <MobileMenu
          activeReservationData={allTripsData[allTripsData.length - 1]}
          contactOwner={{
            name: "Amanda Perry",
            title: "Guest Services Associate",
            avatar: "./media/Amanda.png",
          }}
        />
      )}
    </>
  );
};

export { Trips };

